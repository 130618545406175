<template>
  <div class="sub-container">
    <span class="mt-n3 ml-6 head-sub-text">
      {{ routeParams ? "Edit" : "Add" }} Company</span
    >
    <v-card class="mx-6 mt-6">
      <v-card-text>
        <v-form ref="subForm" v-model="isValid">
          <v-row class="mt-n6"
            ><v-col class="mx-3 mt-3" :cols="12" :md="2" :sm="3"
              >Company name</v-col
            ><v-col :cols="12" :md="6" :sm="3" align-self="start"
              ><v-text-field
                class="mt-2 mx-auto"
                ref="fname"
                :rules="rules.required"
                v-model="formData.name"
                density="compact"
                style="color: #3c3731"
                variant="outlined" /></v-col
          ></v-row>
          <v-row class="mt-n6"
            ><v-col class="mx-3" :cols="12" :md="2" :sm="3">Domain</v-col
            ><v-col :cols="12" :md="6" :sm="3" align-self="start"
              ><v-text-field
                class="mt-2 mx-auto"
                ref="lname"
                :rules="rules.required"
                v-model="formData.domain"
                density="compact"
                style="color: #3c3731"
                variant="outlined" /></v-col
          ></v-row>
          <v-row class="mt-n6"
            ><v-col class="mx-3" :cols="12" :md="2" :sm="3">Sender Email</v-col
            ><v-col :cols="12" :md="6" :sm="3" align-self="start"
              ><v-text-field
                class="mt-2 mx-auto"
                ref="lname"
                :rules="rules.email"
                v-model="formData.allowed_domains"
                density="compact"
                style="color: #3c3731"
                variant="outlined" /></v-col
          ></v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="ml-6">
        <v-btn
          @click="routeParams ? edit() : save()"
          color="#50568e"
          width="80"
          variant="flat"
        >
          <span class="btn-text" v-if="!loadingState">
            {{ routeParams ? "Edit" : "save" }}</span
          >
          <v-progress-circular
            :width="3"
            color="#50568e"
            indeterminate
            v-if="loadingState"
          ></v-progress-circular>
        </v-btn>

        <v-btn
          color="#343a40"
          width="80"
          variant="flat"
          :to="{ name: 'Companies' }"
        >
          <span class="btn-text">Cancel</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import eventBus from "@/utils/eventBus";
export default {
  name: "addCompany",
  beforeRouteEnter(to, from, next) {
    next(() => {});
  },

  data() {
    return {
      isValid: false,
      allSubscribers: [],
      loadingState: false,
      screenSize: "",
      oldValue: false,
      test: [],
      newFiltered: [],
      formData: {
        name: "",
        domain: "",
        allowed_domains: "",
        allowed_sender_emails: [],
      },

      rules: {
        required: [(value) => !!value || "Required."],
        email: [
          (value) => !!value || "Required.",
          (value) => /.+@.+\..+/.test(value) || "Invalid email address.",
        ],
        password: [(value) => !!value || "Required."],
      },
    };
  },

  computed: {
    loaderState() {
      return this.$store.getters["subscribers/subscribersGetters"](
        "loaderState"
      );
    },

    routeId() {
      return parseInt(this.$route.params.id);
    },

    listEdited() {
      return this.lists
        ? this.lists
            .filter((f) => f.id === parseInt(this.$route.params.id))
            .shift()
        : {};
    },
  },

  mounted() {
    this.checkScreenSize(); // Check screen size initially
    window.addEventListener("resize", this.checkScreenSize);
    eventBus.on("redirectToList", () => {
      this.$router.push({ name: "Companies" });
    });
  },
  methods: {
    checkScreenSize() {
      const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
      this.screenSize = isSmallScreen ? "Small" : "Large";
    },
    save() {
      if (!this.isValid) {
        this.$refs.subForm.validate();
      } else {
        this.formData.allowed_sender_emails.push(this.formData.allowed_domains);
        // this.formData.subscribers = [...this.newFiltered];
        this.$store.dispatch("roles/saveCompany", this.formData);
      }
    },

    edit() {
      if (!this.isValid) {
        this.$refs.subForm.validate();
      } else {
        this.formData.listId = parseInt(this.$route.params.id);
        this.formData.subscribers = [...this.newFiltered];

        this.$store.dispatch("roles/editCompany", this.formData);
      }
    },

    getObject(val) {
      console.log(val);
      return val;
    },
  },

  watch: {
    loaderState: {
      handler: function (newValue, oldValue) {
        this.oldValue = oldValue;
        this.loadingState = newValue;
      },
      immediate: true,
    },
    listEdited: {
      handler: function () {
        if (this.listEdited !== "undefined") {
          this.formData = { ...this.listEdited };
        }
      },
    },
    test: {
      handler: function () {
        const subscriberIds = this.test.map((subscriber) => subscriber.id);
        this.allSubscribers = [...subscriberIds];
      },
      immediate: true,
    },
    allSubscribers: {
      handler: function () {
        if (this.allSubscribers.length > 0) {
          let filteredObjects = [];
          this.allSubscribers.forEach((id) => {
            let obj = this.test.find((item) => item.id === id);
            if (obj) {
              delete obj.groups,
                delete obj.is_subscribed,
                delete obj.is_blacklisted,
                filteredObjects.push(obj);
            }
          });
          this.newFiltered = filteredObjects;
        }
      },
      immediate: true,
    },
    singleList: {
      handler: function () {
        const subscriberIds2 = this.singleList.map(
          (subscriber) => subscriber.id
        );
        this.allSubscribers = [...subscriberIds2];
      },
    },
  },
};
</script>

<style>
@import url("../rolesStyles.css");
</style>
