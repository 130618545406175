const appName = "const";

class AuthService {
  constructor() {
    this.token = window.localStorage.getItem(`${appName}_token`);
    this.user = window.localStorage.getItem(`${appName}_user`);
  }

  check() {
    return !!this.token;
  }

  setUser(user) {
    window.localStorage.setItem(`${appName}_names`, JSON.stringify(user));
    this.user = user;
  }

  removeSetUser() {
    window.localStorage.removeItem(`${appName}_user`);
  }

  login(token) {
    window.localStorage.setItem(`${appName}_token`, token);
    // window.localStorage.setItem(`${appName}_user`, JSON.stringify(user));
    this.token = token;
    // const appUrl = process.env.VUE_APP_URL;
    // window.location.href = `${appUrl}dashboard`;
    location.reload();

    
  }

  logout() {
    window.localStorage.removeItem(`${appName}_token`);
    window.localStorage.removeItem(`${appName}_user`);
    window.localStorage.removeItem(`activeMenu`);
    window.location.href = "/";
  }
  timerLogout() {
    window.localStorage.removeItem(`${appName}_token`);
    window.localStorage.removeItem(`${appName}_user`);
    window.localStorage.removeItem(`activeMenu`);
    window.location.href = "/";
  }
}

export default new AuthService();
