<template>
  <div class="sub-container">
    <v-card-actions>
      <span class="mt-n3 ml-6 head-text">All Blacklisted Subscribers</span>
      <v-spacer />

    </v-card-actions>

    <v-card-actions class="ml-8">




      <v-spacer />
      <div class="search-field">
        <v-text-field v-model="search" label="Search" prepend-inner-icon="mdi-magnify" variant="outlined"
          density="compact" hide-details single-line />
      </div>
    </v-card-actions>
    <v-card class="ml-3">
      <v-data-table :items="blacklistedSubs.length > 0 ? blacklistedSubs : []" :headers="headers" item-value="id" :search="search">
        <template #[`item.status`]="{ item }">
          <v-chip :color="getColor(item.selectable.is_subscribed)" variant="flat" size="x-small">
            <span class="chip-text">{{
              item.selectable.is_subscribed ? "subscribed" : "unsubscribed"
            }}</span>
          </v-chip>
        </template>

        <template #[`item.blacklist`]="{ item }">
          <v-chip v-if="item.selectable.is_blacklisted" :color="getBlackColor(item.selectable.is_blacklisted)"
            variant="flat" size="x-small">
            <span class="chip-text">{{
              item.selectable.is_blacklisted ? "blacklisted" : ""
            }}</span>
          </v-chip>
        </template>

        <template #[`item.actions`]="{ item }">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-icon v-bind="props"> mdi-dots-vertical</v-icon>
            </template>

            <v-list>
              <!-- <v-list-item>
                  <v-list-item-title class="list-title"
                    >Remove from blacklist</v-list-item-title
                  >
                </v-list-item> -->
              <!-- <v-list-item
                  class="mt-n3"
                  :to="{
                    name: 'AddSubscriber',
                    params: { id: item.selectable.id },
                  }"
                >
                  <v-list-item-title class="list-title">Edit</v-list-item-title>
                </v-list-item> -->
              <!-- <v-list-item
                  class="mt-n3"
                  @click="unSubscribe(item.selectable.id)"
                >
                  <v-list-item-title class="list-title"
                    >unsubscribe</v-list-item-title
                  >
                </v-list-item> -->
              <v-list-item class="mt-n3" @click="RemoveFromBlacklist(item.selectable.id)">
                <v-list-item-title class="list-title">Remove from blacklist</v-list-item-title>
              </v-list-item>
              <!-- <v-list-item class="mt-n3" @click="deleteSub(item.selectable.id)">
                  <v-list-item-title class="list-title">Delete</v-list-item-title>
                </v-list-item> -->
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
  <v-dialog v-model="loaderState" max-width="500">
      <v-card>
        <v-card-actions><v-spacer />Please Wait <v-spacer /></v-card-actions>
        <v-card-actions><v-spacer />
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>

          <v-spacer />
        </v-card-actions>

        <v-progress-linear class="mx-2 mt-n3" color="teal" indeterminate></v-progress-linear>
        <v-card-actions></v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: "BlacklistedSubs",
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("subscribers/getBlackListedSubscribers");

    });
  },

  data() {
    return {
      groups: [],
      subStatus: "",
      menu: false,
      menu2: false,
      menu3: false,
      search: "",
      isValid: false,
      loadingState: false,
      screenSize: "",
      oldValue: false,

      selected: [],

      headers: [
        {
          title: "Name",
          key: "fullName",
          value: (item) => `${item.first_name} ${item.last_name}`,
        },
        { title: "Email", key: "email" },
        {
          title: "Status",
          key: "status",
          value: (item) => `${item.is_subscribed}`,
        },
        {
          title: "BlackList",
          key: "blacklist",
          value: (item) => `${item.is_blacklisted}`,
        },
        { title: "Actions", key: "actions" },
      ],
    };
  },

  computed: {

    blacklistedSubs() {
      return this.$store.getters["subscribers/subscribersGetters"](
        "blacklistedSubs"
      );
    },
    loaderState() {
      return this.$store.getters["subscribers/subscribersGetters"](
        "loaderState"
      );
    },
  },

  mounted() {
    this.checkScreenSize(); // Check screen size initially
    window.addEventListener("resize", this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
      this.screenSize = isSmallScreen ? "Small" : "Large";
    },



    RemoveFromBlacklist(val) {
      alert("Are you sure you want remove subscriber from blacklist ?");
      const data = {
        subdata: {
          is_blacklisted: false,
        },
        id: parseInt(val),
      };
      this.$store.dispatch("subscribers/unsubSubscriber", data);
    },

    getColor(val) {
      if (val) return "#46c35f";
      else return "orange";
    },
    getBlackColor(val) {
      if (val) return "black";
      else return "";
    },
    changeAvatarColor(item) {
      // Change the color of the clicked avatar
      item.avatarProps.color = "red"; // Change to desired color
    },
    redirectToAdd() {
      this.$router.push({ name: "AddSubscriber" });
    },
  },

  watch: {
    loaderState: {
      handler: function (newValue, oldValue) {
        this.oldValue = oldValue;
        this.loadingState = newValue;
      },
      immediate: true,
    },
  },
};
</script>

<style>
@import url("../subscriberStyle.css");
</style>