<template>
    <v-container fluid class="container">
        <v-card elevation="1" variant="flat" class="mx-auto align-center mt-n1" color="#ffffff" max-width="550"
            height="900">
            <div class="main-card"></div>
            <v-img class="mx-auto" height="70" max-width="170" src="./main.png"></v-img>
            <div class="text-center mt-3">
                <!-- Wrap "Hi There" in a div and add text-center class -->
                <!-- <span class="main-text">Hi There</span><br /> -->
                <span class="main-text">Reset Your Password</span>
            </div>
            <v-card-text class="login-form">
                <v-form ref="loginForm" v-model="isValid">
                    <span>Password</span>
                    <v-text-field class="mt-2" ref="password" :rules="rules.password" v-model="formData.password"
                        density="compact" placeholder="Enter your Password"
                        :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'" :type="visible ? 'text' : 'password'"
                        @click:append-inner="visible = !visible" style="color: #3c3731" variant="outlined" />

                    <span>Confirm Password</span>
                    <v-text-field class="mt-2" ref="confirmPassword" :rules="rules.confirmPassword"
                        v-model="formData.confirmPassword" density="compact" placeholder="Enter your Password"
                        :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'" :type="visible ? 'text' : 'password'"
                        @click:append-inner="visible = !visible" style="color: #3c3731" variant="outlined" />
                </v-form>
            </v-card-text>
            <v-card-actions class="mt-n6">
                <v-btn @click="Login()" block class="text-none text-subtitle-1" color="#50568e" elevation="4"
                    variant="flat">
                    <span class="login--text" v-if="!loginState">Reset</span>
                    <v-progress-circular :width="3" color="#50568e" indeterminate
                        v-if="loginState"></v-progress-circular>
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-dialog v-model="dialog" max-width="500">
            <v-card>
                <v-card-actions><v-spacer />Please Wait for Redirection Back to Login Page <v-spacer /></v-card-actions>
                <v-card-actions><v-spacer />
                    <div class="ball"></div>
                    <div class="ball"></div>
                    <div class="ball"></div>
                    <div class="ball"></div>
                    <div class="ball"></div>
                    <v-spacer />
                </v-card-actions>
                <v-progress-linear class="mx-2 mt-n3" color="teal" indeterminate></v-progress-linear>
                <v-card-actions></v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import eventBus from "@/utils/eventBus";
// import AuthService from "@/modules/auth/views/authService";
export default {
    name: "registerPage",


    data() {
        return {
            dialog:false,
            isValid: false,
            loginState: false,
            screenSize: "",
            visible: false,
            formData: {
                confirmPassword: "",
                password: "",
                token: "",
            },
            showPassword: false,
            rules: {
                password: [
                    (v) => !!v || "Password is required",
                    (v) => v.length >= 8 || "Password must be at least 8 characters",
                ],
                confirmPassword: [
                    (v) => !!v || "Confirm Password is required",
                    (v) => v.length >= 8 || "Password must be at least 8 characters",
                    (v) => v === this.formData.password || "Passwords must match",
                ],
            },
        };
    },

    computed: {},

    mounted() {
        this.checkScreenSize(); // Check screen size initially
        window.addEventListener("resize", this.checkScreenSize);
        eventBus.on("acceptInvite", () => {
            this.dialog = true
            this.$router.push({ name: "signIn" });
        });
    },
    methods: {
        Login() {
            if (!this.isValid) {
                this.$refs.loginForm.validate();
            } else {
                this.loginState = true;
               
                this.formData.token = this.$route.query.token;
                this.$store.dispatch("auth/acceptInvite", this.formData);
                
            }
        },

        checkScreenSize() {
            const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
            this.screenSize = isSmallScreen ? "Small" : "Large";
        },
        redirect() {
            this.$router.push({ name: "test" });
        },
    },

    watch: {},
};
</script>

<style>
@import url("../authStyle.css");
</style>
