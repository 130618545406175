<template>
  <div class="sub-container">
    <v-card-actions>
      <span class="mt-n3 ml-6 head-sub-text">Emails By Campaigns</span>
      <v-spacer />

      <!-- <v-btn color="#50568e" variant="flat" class="text-none" size="small" :to="{name:'AddCampaign'}">
        <span class="btn-text" >Add Campaign</span>
      </v-btn> -->
    </v-card-actions>

    <v-card-actions class="ml-8">


      <v-spacer />
      <div class="search-field">
        <v-text-field v-model="search" label="Search" prepend-inner-icon="mdi-magnify" variant="outlined"
          density="compact" hide-details single-line />
      </div>
    </v-card-actions>
    <v-card class="ml-3">
      <v-data-table :items="campaigns.length > 0 ? campaigns : []" :headers="headers" item-value="id" :search="search">
        <template #[`item.estatus`]="{ item }">
          <v-chip :color="getColor(item.selectable.is_active)" variant="flat" size="x-small">
            <span class="chip-text">{{
              item.selectable.is_active ? "active" : "inactive"
            }}</span>
          </v-chip>
        </template>
        <template #[`item.status`]="{ item }">
          <v-chip :color="getStatusColor(item.selectable.status)" variant="flat" size="x-small">
            <span class="chip-text">{{
              item.selectable.status
            }}</span>
          </v-chip>
        </template>
         <template #[`item.date`]="{ item }">
          {{
            formatDate(item.selectable.created_at)
          }}
        </template>

        <template #[`item.actions`]="{ item }">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-icon v-bind="props"> mdi-dots-vertical</v-icon>
            </template>

            <v-list>

              <v-list-item class="mt-n3" :to="{ name: 'SingleCampaign', params: { id: item.selectable.id } }">
                <v-list-item-title class="list-title">View Details</v-list-item-title>
              </v-list-item>

              <!-- <v-list-item class="mt-n3" @click="deleteItem(item.selectable.id)">
                <v-list-item-title class="list-title" >Delete</v-list-item-title>
              </v-list-item> -->
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
  <v-dialog v-model="loaderState" max-width="500">
    <v-card>

      <v-card-actions><v-spacer />Please Wait <v-spacer /></v-card-actions>
      <v-card-actions><v-spacer />
        <div class="ball"></div>
        <div class="ball"></div>
        <div class="ball"></div>
        <div class="ball"></div>
        <div class="ball"></div>

        <v-spacer />
      </v-card-actions>


      <v-progress-linear class="mx-2 mt-n3" color="teal" indeterminate></v-progress-linear>
      <v-card-actions></v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "emailsByCampaign",
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("campaign/getCampaigns");

    });
  },

  data() {
    return {
      groups: [],
      menu: false,
      menu2: false,
      menu3: false,
      search: "",
      isValid: false,
      loadingState: false,
      screenSize: "",
      oldValue: false,

      selected: [],

      headers: [

        { title: "campaign_name", key: "name" },
        { title: "campaign_status", key: "estatus" },
        { title: "delivery_status", key: "status" },
        { title: "No. Sent", key: "number_sent" },
        { title: "No. Bounced", key: "number_bounced" },
        { title: "No. Clicked", key: "number_clicked" },
        { title: "No. Opened", key: "number_opened" },
        {
          title: "date",
          key: "date",

        },


        { title: "Actions", key: "actions" },
      ],
    };
  },

  computed: {
    campaigns() {
      return this.$store.getters["campaign/campaignGetters"](
        "campaigns"
      );
    },
    loaderState() {
      return this.$store.getters["campaign/campaignGetters"]("loaderState");
    },


  },

  mounted() {
    this.checkScreenSize(); // Check screen size initially
    window.addEventListener("resize", this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
      this.screenSize = isSmallScreen ? "Small" : "Large";
    },
    deleteItem(val) {
      alert('Are you sure you want to delete template ?')
      this.$store.dispatch("campaign/deleteCampaign", parseInt(val));
    },

    getColor(val) {
      if (val) return "#46c35f";
      else return "orange";
    },
    getStatusColor(val) {
      if (val === 'draft') return "orange";
      else return "green";
    },
    changeAvatarColor(item) {
      // Change the color of the clicked avatar
      item.avatarProps.color = "red"; // Change to desired color
    },
    formatDate(dateString) {
      const date = new Date(dateString);

      // Extract individual components
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
      const day = String(date.getDate()).padStart(2, '0');
     
      // Format as "YYYY-MM-DD HH:MM:SS"
      return `${year}-${month}-${day}`;
    }
  },

  watch: {
    loaderState: {
      handler: function (newValue, oldValue) {
        this.oldValue = oldValue;
        this.loadingState = newValue;
      },
      immediate: true,
    },
  },
};
</script>

<style>
@import url("../emailStyle.css");
</style>
