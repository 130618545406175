<template>
  <router-view />
</template>

<script>
// import AuthService from "./modules/auth/views/authService";

export default {
  name: "MainPage",

  components: {},

  data() {
    return {
      screenSize: "",
    };
  },

  computed: {},

  mounted() {
  
    this.checkScreenSize(); // Check screen size initially
    window.addEventListener("resize", this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
      this.screenSize = isSmallScreen ? "Small" : "Large";

    },
  },

  watch: {},
};
</script>

<style>
/* @import url("./style.css"); */
</style>
