import stats from "../dashboard/components/stats";
import dashboard from "../dashboard/views/dashboard";

const dashboardRoutes = [
  {
    path: "/dashboard",
    component: dashboard,
    meta: { requiresAuth: true },
    children: [
      {
        path: "stats",
        name: "Dashboard",
        component: stats,
        meta: { requiresAuth: true },
      },
    ],
  },
];

export default dashboardRoutes;
