import LoginPage from "../auth/components/login";
import register from "../auth/components/register";
import ForgotPage from "../auth/components/forgotPassword";
import authLayout from "./views/authLayout";

// import table from '../auth/components/table';
// import RegistrationPage from '@/views/RegistrationPage.vue';

const authRoutes = [
  {
    path: "",
    component: authLayout,
    children: [
      {
        path: "",
        name: "signIn",
        component: LoginPage,
      },
      {
        path: "/forgot",
        name: "forgot",
        component: ForgotPage,
      },
      {
        path: "/register",
        name: "register",
        component: register,
      },
    ],
  },
];

export default authRoutes;
