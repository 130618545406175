<template>
  <div class="sub-container">
    <span class="mt-n3 ml-6 head-sub-text">
      {{ routeParams ? "Edit" : "Add" }} Subscriber</span>
    <v-card class="mx-6 mt-6">
      <v-card-text>
        <v-form ref="subForm" v-model="isValid">
          <v-row><v-col class="mx-3 mt-4" :cols="12" :md="2" :sm="3">Email</v-col><v-col :cols="12" :md="6" :sm="3"
              align-self="start"><v-text-field class="mt-2 mx-auto" ref="email" :rules="rules.email"
                v-model="formData.email" density="compact" style="color: #3c3731" variant="outlined" /></v-col></v-row>
          <v-row class="mt-n6"><v-col class="mx-3 mt-3" :cols="12" :md="2" :sm="3">First name</v-col><v-col :cols="12"
              :md="6" :sm="3" align-self="start"><v-text-field class="mt-2 mx-auto" ref="fname" :rules="rules.required"
                v-model="formData.first_name" density="compact" style="color: #3c3731"
                variant="outlined" /></v-col></v-row>
          <v-row class="mt-n6"><v-col class="mx-3" :cols="12" :md="2" :sm="3">Last name</v-col><v-col :cols="12" :md="6"
              :sm="3" align-self="start"><v-text-field class="mt-2 mx-auto" ref="lname" :rules="rules.required"
                v-model="formData.last_name" density="compact" style="color: #3c3731"
                variant="outlined" /></v-col></v-row>
          <v-row class="mt-n6"><v-col class="mx-3" :cols="12" :md="2" :sm="3">List(s)</v-col><v-col :cols="12" :md="6"
              :sm="3" align-self="start">
              <v-autocomplete v-model="selectedGroup" :items="lists.length > 0 ? lists : []" color="blue-grey-lighten-2"
                item-title="name" item-value="id" label="Select" chips closable-chips density="compact"
                style="color: #3c3731" variant="outlined"></v-autocomplete></v-col></v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="ml-6">
        <v-btn @click="routeParams ? edit() : save()" color="#50568e" width="80" variant="flat">
          <span class="btn-text" v-if="!loadingState">Save</span>
          <v-progress-circular :width="3" color="#50568e" indeterminate v-if="loadingState"></v-progress-circular>
        </v-btn>

        <v-btn color="#343a40" width="80" variant="flat" :to="{ name: 'subscriberList' }">
          <span class="btn-text">Cancel</span>
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="loaderState" max-width="500">
      <v-card>
        <v-card-actions><v-spacer />Please Wait <v-spacer /></v-card-actions>
        <v-card-actions><v-spacer />
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>

          <v-spacer />
        </v-card-actions>

        <v-progress-linear class="mx-2 mt-n3" color="teal" indeterminate></v-progress-linear>
        <v-card-actions></v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import eventBus from "@/utils/eventBus";
export default {
  name: "SubPage",
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("subscribers/getLists");
      // v.$store.dispatch("subscribers/getSubscribers");


      if (v.$route.params.id !== "") {
        v.$store.dispatch("subscribers/getSingleSub", parseInt(v.$route.params.id));
      }
    });
  },

  data() {
    return {
      isValid: false,
      loadingState: false,
      screenSize: "",
      test: [],
      selectedGroup: null,
      oldValue: false,
      formData: {
        email: "",
        first_name: "",
        last_name: "",
        groups: [],
      },

      rules: {
        required: [(value) => !!value || "Required."],
        email: [
          (value) => !!value || "Required.",
          (value) => /.+@.+\..+/.test(value) || "Invalid email address.",
        ],
        password: [(value) => !!value || "Required."],
      },
    };
  },

  computed: {
    lists() {
      return this.$store.getters["subscribers/subscribersGetters"]("Lists");
    },
    loaderState() {
      return this.$store.getters["subscribers/subscribersGetters"](
        "loaderState"
      );
    },
    subscribers() {
      return this.$store.getters["subscribers/subscribersGetters"](
        "subscribers"
      );
    },
    singleSub() {
      return this.$store.getters["subscribers/subscribersGetters"](
        "singleSub"
      );
    },

    // testRoute() {
    //   return this.$route.params.id;
    // },

    routeParams() {
      return !!this.$route.params.id;
    },

    subscriberEdited() {
      return this.subscribers
        ? this.subscribers
          .filter((f) => f.id === parseInt(this.$route.params.id))
          .shift()
        : {};
    },
  },

  mounted() {
    this.checkScreenSize(); // Check screen size initially
    window.addEventListener("resize", this.checkScreenSize);
    eventBus.on("subAdded", () => {
      this.$router.push({ name: "subscriberList" });
    });
  },
  methods: {
    checkScreenSize() {
      const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
      this.screenSize = isSmallScreen ? "Small" : "Large";
    },
    save() {
      if (!this.isValid) {
        this.$refs.subForm.validate();
      } else {
        if (this.selectedGroup === null) {
          eventBus.emit("showToast", {
            type: "error",
            message: "Please Select List",
          });
        } else {
          this.$store.dispatch("subscribers/saveSubscriber", this.formData);
        }
      }
    },

    edit() {
      if (!this.isValid) {
        this.$refs.subForm.validate();
      } else {
        if (this.selectedGroup === null) {
          eventBus.emit("showToast", {
            type: "error",
            message: "Please Select List",
          });
        } else {
          const data = {
            id: parseInt(this.$route.params.id),
            alldata: { ...this.formData },
          };
          this.$store.dispatch("subscribers/editSubscriber", data);
        }
      }
    },
  },

  watch: {
    loaderState: {
      handler: function (newValue, oldValue) {
        this.oldValue = oldValue;
        this.loadingState = newValue;
      },
      immediate: true,
    },
    singleSub: {
      handler: function () {
        if (this.singleSub !== undefined) {
          this.formData = { ...this.singleSub };
          this.selectedGroup = this.singleSub.groups[0];
        }
      },
    },
    // subscriberEdited: {
    //   handler: function () {
    //     if (this.subscriberEdited !== undefined) {
    //       this.formData = { ...this.subscriberEdited };
    //       this.selectedGroup = this.subscriberEdited.group_id;
    //     }
    //   },
    // },
    selectedGroup: {
      handler: function () {
        if (this.selectedGroup !== null) {
          if (this.test.length > 0) {
            this.test = [];
          }
          this.test.push(this.selectedGroup);
          this.formData.groups = [...this.test];
        }
      },
    },
  },
};
</script>

<style>
@import url("../subscriberStyle.css");
</style>
