import { createApp } from "vue";
import App from "./App.vue";
import vuetifyApp from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import ToastPlugin from "./utils/ToastPlugin";
import eventBus from "./utils/eventBus";
import CanvasJSChart from '@canvasjs/vue-charts';



const app = createApp(App);

app.use(router);
app.use(store);
// app.use(store(router))
app.use(ToastPlugin);
eventBus.on("showToast", ({ type, message }) => {
  app.config.globalProperties.$toast[type](message);
});
app.use(CanvasJSChart);
app.use(vuetifyApp);
app.mount("#app");


