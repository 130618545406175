<template>
    <v-app>
        <v-main>
            <router-view />
        </v-main>


    </v-app>
</template>

<script>

import AuthService from "@/modules/auth/views/authService";

export default {
    name: "authLayout",

    beforeRouteEnter(to, from, next) {
        next((v) => {
            if (AuthService.check()) {
                v.$router.replace({
                    name: "Dashboard",
                });
            }
        });
    },

    data: function () {
        return {
            TIMEOUT: 60 * 1000,
            lastUserActivity: Date.now(),
            lastLoginTimestamp: 0,
        };
    },

    computed: {

    },

    methods: {
        disableControlShiftC(event) {
            if (event.ctrlKey && event.shiftKey && event.key === "C") {
                event.preventDefault();
                console.log("Control+Shift+C shortcut is disabled.");
            }
        },
        checklastLogin() {
            const latest = Math.floor(Date.now() / 1000); // Current timestamp in seconds
            const last = window.localStorage.getItem("recentLoginTime");
            const DifferenceBtwn = latest - last;

            const oneMinuteInSeconds = 1 * 60 * 60; // 1 minute in seconds

            if (last !== null) {
                if (DifferenceBtwn > oneMinuteInSeconds) {
                    AuthService.logout();
                }
            }
        },
    },

    watch: {},
    directives: {
        "disable-right-click": {
            inserted(el) {
                el.addEventListener("contextmenu", disableEvent);
                el.addEventListener("auxiliaryclick", disableEvent);
            },
            unbind(el) {
                el.removeEventListener("contextmenu", disableEvent);
                el.removeEventListener("auxiliaryclick", disableEvent);
            },
        },
    },
    mounted() {
        window.addEventListener("keydown", this.disableControlShiftC);

        this.checklastLogin();
    },
    beforeUnmount() {
        window.removeEventListener("keydown", this.disableControlShiftC);
    },
};

function disableEvent(event) {
    event.preventDefault();
}
</script>

<style scoped></style>