// import addSubscriber from "../subscribers/components/addSubscriber";

import dashboard from "../dashboard/views/dashboard";
import emailList from "../emails/components/emailList";
import emailsByCampaign from "../emails/components/emailsByCampaign";
import SingleCampaign from "../emails/components/SingleCampaign";

const emailRoutes = [
  {
    path: "/",
    component: dashboard,
    meta: { requiresAuth: true },
    children: [
      {
        path: "single-campaign/:id",
        name: "SingleCampaign",
        component: SingleCampaign,
        meta: { requiresAuth: true },
      },
      {
        path: "all-emails",
        name: "emailList",
        component: emailList,
        meta: { requiresAuth: true },
      },
      {
        path: "emails-by-campaign",
        name: "emailsByCampaign",
        component: emailsByCampaign,
        meta: { requiresAuth: true },
      },
    ],
  },
];

export default emailRoutes;
