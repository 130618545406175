<template>
  <div class="sub-container">
    <v-card-actions>
      <span class="mt-n3 ml-6 head-sub-text">All Templates</span>
      <v-spacer />

      <v-btn color="#50568e" variant="flat" class="text-none" size="small" :to="{ name: 'addTemplate' }">
        <span class="btn-text">Add Template</span>
      </v-btn>
    </v-card-actions>

    <v-card-actions class="ml-8">


      <v-spacer />
      <div class="search-field">
        <v-text-field v-model="search" label="Search" prepend-inner-icon="mdi-magnify" variant="outlined"
          density="compact" hide-details single-line />
      </div>
    </v-card-actions>
    <v-card class="ml-3">
      <v-data-table :items="templates.length > 0 ? templates : []" :headers="headers" item-value="id"
        :search="search">
        <template #[`item.status`]="{ item }">
          <v-chip :color="getColor(item.selectable.is_subscribed)" variant="flat" size="x-small">
            <span class="chip-text">{{
              item.selectable.is_subscribed ? "subscribed" : "unsubscribed"
            }}</span>
          </v-chip>
        </template>

        <template #[`item.actions`]="{ item }">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-icon v-bind="props"> mdi-dots-vertical</v-icon>
            </template>

            <v-list>
              <v-list-item class="mt-n3" @click="viewItem(item.selectable.content)">
                <v-list-item-title class="list-title">View</v-list-item-title>
              </v-list-item>

              <v-list-item class="mt-n3" :to="{ name: 'addTemplate', params: { id: item.selectable.id } }">
                <v-list-item-title class="list-title">Edit</v-list-item-title>
              </v-list-item>

              <v-list-item class="mt-n3" @click="deleteItem(item.selectable.id)">
                <v-list-item-title class="list-title">Delete</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
  <v-dialog v-model="loaderState" max-width="500">
    <v-card>

      <v-card-actions><v-spacer />Please Wait <v-spacer /></v-card-actions>
      <v-card-actions><v-spacer />
        <div class="ball"></div>
        <div class="ball"></div>
        <div class="ball"></div>
        <div class="ball"></div>
        <div class="ball"></div>

        <v-spacer />
      </v-card-actions>


      <v-progress-linear class="mx-2 mt-n3" color="teal" indeterminate></v-progress-linear>
      <v-card-actions></v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="dialog">
    <v-card>

      <div v-html="tempPreview"></div>
      <v-card-actions>
        <v-spacer />

        <v-btn color="#343a40" width="80" variant="flat" @click="dialog = false">
          <span class="btn-text">Close</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="deleteDialog">
    <v-card>

      <div v-html="tempPreview"></div>
      <v-card-actions>
        <v-spacer />

        <v-btn color="#343a40" width="80" variant="flat" @click="dialog = false">
          <span class="btn-text">Close</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AllTemplates",
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("templates/getTemplates");

    });
  },

  data() {
    return {
      groups: [],
      menu: false,
      menu2: false,
      menu3: false,
      search: "",
      isValid: false,
      loadingState: false,
      screenSize: "",
      oldValue: false,
      tempPreview: "",
      dialog: false,

      selected: [],

      headers: [

        { title: "Name", key: "name" },
        { title: "Actions", key: "actions" },
      ],
    };
  },

  computed: {
    templates() {
      return this.$store.getters["templates/templatesGetters"](
        "Templates"
      );
    },

    loaderState() {
      return this.$store.getters["templates/templatesGetters"](
        "loaderState"
      );
    },
  },

  mounted() {
    this.checkScreenSize(); // Check screen size initially
    window.addEventListener("resize", this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
      this.screenSize = isSmallScreen ? "Small" : "Large";
    },
    deleteItem(val) {
      alert('Are you sure you want to delete template ?')
      this.$store.dispatch("templates/deleteTemplate", parseInt(val));
    },
    viewItem(val) {
      this.dialog = true
      this.tempPreview = val

    },

    getColor(val) {
      if (val) return "#46c35f";
      else return "orange";
    },
    changeAvatarColor(item) {
      // Change the color of the clicked avatar
      item.avatarProps.color = "red"; // Change to desired color
    },
  },

  watch: {
    loaderState: {
      handler: function (newValue, oldValue) {
        this.oldValue = oldValue;
        this.loadingState = newValue;
      },
      immediate: true,
    },
  },
};
</script>

<style>
@import url("../templatesStyle.css");
</style>