import addSubscriber from "../subscribers/components/addSubscriber";
import subscriberList from "../subscribers/components/subscriberList";
import blackListedSubs from "../subscribers/components/blackListedSubs";
import importSubs from "../subscribers/components/importSubs"
import dashboard from "../dashboard/views/dashboard";

const subscribersRoutes = [
  {
    path: "/",
    component: dashboard,
    meta: { requiresAuth: true },
    children: [
      {
        path: "subscriber/:id?",
        name: "AddSubscriber",
        component: addSubscriber,
        meta: { requiresAuth: true },
      },
      {
        path: "sub-list",
        name: "subscriberList",
        component: subscriberList,
        meta: { requiresAuth: true },
      },
      {
        path: "blacklisted-list",
        name: "blackListedSubs",
        component: blackListedSubs,
        meta: { requiresAuth: true },
      },
      {
        path: "import-subs/:id?",
        name: "importSubs",
        component: importSubs,
        meta: { requiresAuth: true },
      },
    ],
  },
];

export default subscribersRoutes;
