<!-- Toast.vue -->
<template>
    <div v-if="toast.show" :class="['toast', toast.type]">
        {{ toast.message }}
       
        <button class="close-button ml-n3" @click="hideToast">×</button>
    </div>
</template>

<script>
export default {
    name: "ToastPlugin",
    data() {
        return {
            toast: {
                show: false,
                type: '',
                message: '',
            },
        };
    },
    methods: {
        showToast({ type, message }) {
            this.toast.type = type;
            this.toast.message = message;
            this.toast.show = true;
            setTimeout(() => {
                this.toast.show = false;
            }, 3000);
        },
         hideToast() {
            this.toast.show = false;
        },


    },
};
</script>

<style>
.toast {
    /* position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    border-radius: 4px;
    color: #fff;
    max-width: 300px; */

    position: fixed;
    top: 20px;
    /* Adjust as needed */
    right: 20px;
    /* Adjust as needed */
    padding: 10px 20px;
    border-radius: 4px;
    color: #fff;
    width: 400px;
    z-index: 9999;
    height: 50px;
    /* Ensure it's on top of other elements */
}

.error {
    background-color: #ff4d4f;
}

.info {
    background-color: #1890ff;
}

.success {
    background-color: #52c41a;
}

.close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  background: transparent;
  color: white;
  cursor: pointer;
  font-size: 20px;
}
</style>