import call from "@/service/http";
import constants from "./campaignConstants";
import eventBus from "@/utils/eventBus";
// import AuthService from "../auth/views/authService";

const campaignModule = {
  namespaced: true,
  state: {
    loaderState: false,
    tabState: 0,
    campaigns: [],
    campaign: {},
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.value;
    },
  },
  getters: {
    campaignGetters: (state) => (val) => state[val],
  },
  actions: {
    saveCampaign: ({ commit }, data) => {
      commit("MUTATE", { state: "loaderState", value: true });
      call("post", constants.campaigns, data)
        .then((res) => {
          if (res.data.status_code === 200) {
            eventBus.emit("showToast", {
              type: "success",
              message: res.data.message,
            });
            commit("MUTATE", { state: "loaderState", value: false });
            commit("MUTATE", { state: "tabState", value: 2 });
            // router.push({ name: "AddCampaign", params:{id: res.data.data.id} });
            eventBus.emit("campaignSaved", res.data.data.id);
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: "Failed to save List",
            });
            commit("MUTATE", { state: "loaderState", value: false });
          }
        })
        .catch((err) => {
          commit("MUTATE", { state: "loaderState", value: false });
          eventBus.emit("showToast", {
            type: "error",
            message: "Error",
          });
          console.log(err);
        });
    },
    getCampaigns: ({ commit }) => {
      commit("MUTATE", { state: "loaderState", value: true });
      call("get", constants.campaigns)
        .then((res) => {
          if (res.data.status_code === 200) {
            commit("MUTATE", { state: "campaigns", value: res.data.data });
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: res.data.message,
            });
          }
          commit("MUTATE", { state: "loaderState", value: false });
        })
        .catch((err) => {
          console.log(err);
          commit("MUTATE", { state: "loaderState", value: false });
        });
    },
    getSingleCampaign: ({ commit }, data) => {
      commit("MUTATE", { state: "loaderState", value: true });
      call("get", constants.singleCampaign(data))
        .then((res) => {
          if (res.data.status_code === 200) {
            commit("MUTATE", { state: "campaign", value: res.data.data });
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: res.data.message,
            });
          }
          commit("MUTATE", { state: "loaderState", value: false });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    sendTestMail: ({ commit }, data) => {
      commit("MUTATE", { state: "loaderState", value: true });
      call("post", constants.sendTestEmail, data)
        .then((res) => {
          if (res.data.status_code === 200) {
            eventBus.emit("showToast", {
              type: "success",
              message: res.data.message,
            });
            commit("MUTATE", { state: "loaderState", value: false });
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: res.data.message,
            });
            commit("MUTATE", { state: "loaderState", value: false });
          }
        })
        .catch((err) => {
          console.log(err);
          commit("MUTATE", { state: "loaderState", value: false });
        });
    },
    sendAllEmails: ({ commit }, data) => {
      commit("MUTATE", { state: "loaderState", value: true });
      call("post", constants.sendAllEmails(data))
        .then((res) => {
          if (res.data.status_code === 200) {
            eventBus.emit("showToast", {
              type: "success",
              message: res.data.message,
            });
            commit("MUTATE", { state: "loaderState", value: false });
            eventBus.emit("emailSent");
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: res.data.message,
            });
            commit("MUTATE", { state: "loaderState", value: false });
          }
        })
        .catch((err) => {
          console.log(err);
          commit("MUTATE", { state: "loaderState", value: false });
        });
    },
    editCampaign: ({ commit, dispatch }, data) => {
      commit("MUTATE", { state: "loaderState", value: true });
      call("patch", constants.patchCampaign(data.id), data.allData)
        .then((res) => {
          if (res.data.status_code === 200) {
            eventBus.emit("showToast", {
              type: "success",
              message: res.data.message,
            });
            dispatch("sendTestMail", {
              subject: data.allData.subject,
              from: "help@tililtech.com",
              to: data.allData.test_email,
              sender: "Tilil Technologies",
              html: data.allData.body,
            });
            commit("MUTATE", { state: "loaderState", value: false });
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: res.data.message,
            });
            commit("MUTATE", { state: "loaderState", value: false });
          }
        })
        .catch((err) => {
          commit("MUTATE", { state: "loaderState", value: false });
          eventBus.emit("showToast", {
            type: "error",
            message: "Error",
          });
          console.log(err);
        });
    },
    editFullCampaign: ({ commit, dispatch }, data) => {
      commit("MUTATE", { state: "loaderState", value: true });
      call("patch", constants.patchCampaign(data.id), data.allData)
        .then((res) => {
          if (res.data.status_code === 200) {
            dispatch("sendAllEmails", data.id);
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: res.data.message,
            });
            commit("MUTATE", { state: "loaderState", value: false });
          }
        })
        .catch((err) => {
          commit("MUTATE", { state: "loaderState", value: false });
          eventBus.emit("showToast", {
            type: "error",
            message: "Error",
          });
          console.log(err);
        });
    },
    deleteCampaign: ({ commit }, data) => {
      commit("MUTATE", { state: "loaderState", value: true });
      call("delete", constants.delCamp(data), data)
        .then((res) => {
          if (res.data.status_code === 200) {
            eventBus.emit("showToast", {
              type: "success",
              message: res.data.message,
            });
            commit("MUTATE", { state: "loaderState", value: false });
            window.location.reload();
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: res.data.message,
            });
            window.location.reload();
            commit("MUTATE", { state: "loaderState", value: false });
          }
        })
        .catch((err) => {
          commit("MUTATE", { state: "loaderState", value: false });
          eventBus.emit("showToast", {
            type: "error",
            message: "Error",
          });
          console.log(err);
        });
    },
  },
};

export default campaignModule;
