export default {
    subscribers: "api/subscriber-groups/",
    saveSub:"api/subscribers/",
    delSub:(id) => `api/subscribers/${id}/`,
    blackSub:"api/subscribers/?is_blacklisted=true",
    subTrue:"api/subscribers/?is_subscribed=true",
    subFalse:"api/subscribers/?is_subscribed=false",
    subID:(id) => `api/subscriber-groups/${id}/`,
    deleteMultiple:"api/subscribers/bulk-delete/",
    pagSub:"api/subscribers/list/"
  }