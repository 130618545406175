import call from "@/service/http";
import constants from "./listConstants";
import eventBus from "@/utils/eventBus";

const listModule = {
  namespaced: true,
  state: {
    loaderState: false,
    singleList: {},
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.value;
    },
  },
  getters: {
    listGetters: (state) => (val) => state[val],
  },
  actions: {
    saveList: ({ commit }, data) => {
      commit("MUTATE", { state: "loaderState", value: true });
      call("post", constants.saveList, data)
        .then((res) => {
          if (res.data.status_code === 200) {
            eventBus.emit("showToast", {
              type: "success",
              message: res.data.message,
            });
            commit("MUTATE", { state: "loaderState", value: false });

            eventBus.emit("redirectToList");
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: "Failed to save List",
            });
            commit("MUTATE", { state: "loaderState", value: false });
          }
        })
        .catch((err) => {
          commit("MUTATE", { state: "loaderState", value: false });
          eventBus.emit("showToast", {
            type: "error",
            message: "Error",
          });
          console.log(err);
        });
    },
    editList: ({ commit }, data) => {
      commit("MUTATE", { state: "loaderState", value: true });
      call("put", constants.putList(data.listId), data)
        .then((res) => {
          if (res.data.status_code === 200) {
            eventBus.emit("showToast", {
              type: "success",
              message: res.data.message,
            });
            commit("MUTATE", { state: "loaderState", value: false });
            window.location.reload();
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: "Failed to add subscriber",
            });
            commit("MUTATE", { state: "loaderState", value: false });
          }
        })
        .catch((err) => {
          commit("MUTATE", { state: "loaderState", value: false });
          eventBus.emit("showToast", {
            type: "error",
            message: "Error",
          });
          console.log(err);
        });
    },
    deleteList: ({ commit }, data) => {
      commit("MUTATE", { state: "loaderState", value: true });
      call("delete", constants.delList(data), data)
        .then((res) => {
          if (res.status === 204) {
            eventBus.emit("showToast", {
              type: "success",
              message: "Deleted successfully",
            });
            commit("MUTATE", { state: "loaderState", value: false });
            window.location.reload();
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: "failed to delete",
            });
            window.location.reload();
            commit("MUTATE", { state: "loaderState", value: false });
          }
        })
        .catch((err) => {
          commit("MUTATE", { state: "loaderState", value: false });
          eventBus.emit("showToast", {
            type: "error",
            message: "Error",
          });
          console.log(err);
        });
    },
    getSingleList: ({ commit }, data) => {
      call("get", constants.singleList(data))
        .then((res) => {
          if (res.data.status_code === 200) {
            commit("MUTATE", { state: "singleList", value: res.data });
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: res.data.message,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};

export default listModule;
