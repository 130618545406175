<template>
    <v-container fluid class="container">
        <v-card elevation="1" variant="flat" class="mx-auto align-center mt-n4 " color="#ffffff" max-width="550"
            height="900">
            <div class="main-card"></div>
            <v-img class="mx-auto" height="70" max-width="170" src="./main.png"></v-img>
            <div class="text-center mt-3">
                <!-- Wrap "Hi There" in a div and add text-center class -->

                <span class="main-text">Reset Password</span>
            </div>
            <v-card-text class="login-form">
                <v-form ref="resetForm" v-model="isValid">
                    <!--User name-->
                    <span>Email</span>
                    <v-text-field class="mt-2" ref="email" :rules="rules.required" v-model="formData.email"
                        density="compact" placeholder="Enter your Email" style="color: #3c3731" variant="outlined" />
                    <!-- Password -->

                </v-form>
            </v-card-text>
            <v-card-actions class="mt-n6">
                <v-btn @click="Request()" block class="text-none text-subtitle-1" color="#50568e" elevation="4"
                    variant="flat">
                    <span class="login--text">Request</span>
                </v-btn>

            </v-card-actions>
            <v-card-actions class="mt-0">

                <v-btn :to="{ name: 'signIn' }" @click="Request()" text class="text-none text-subtitle-1" color="#50568e"
                    variant="text">
                    <v-icon>mdi-arrow-left</v-icon><span class="forgot--text">Back to Login</span>
                </v-btn>
                <v-spacer />

            </v-card-actions>

        </v-card>
        <v-dialog v-model="dialog" max-width="500">
            <v-card>
                <v-card-actions><v-spacer />Please Wait for Redirection Back to Login Page <v-spacer /></v-card-actions>
                <v-card-actions><v-spacer />
                    <div class="ball"></div>
                    <div class="ball"></div>
                    <div class="ball"></div>
                    <div class="ball"></div>
                    <div class="ball"></div>
                    <v-spacer />
                </v-card-actions>
                <v-progress-linear class="mx-2 mt-n3" color="teal" indeterminate></v-progress-linear>
                <v-card-actions></v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
export default {
    name: "testPage",

    data() {
        return {
            dialog: false,
            isValid: false,
            loginState: false,
            screenSize: "",
            visible: false,
            formData: {
                email: "",

            },
            showPassword: false,
            rules: {
                required: [
                    (value) => !!value || "Required."
                ],
                email: [
                    (value) => !!value || "Required.",
                    (value) => /.+@.+\..+/.test(value) || "Invalid email address."
                ],
                password: [(value) => !!value || "Required."],
            },
        };
    },

    computed: {

    },

    mounted() {
       

    },
    methods: {
        Request() {
            if (!this.isValid) {
                this.$refs.resetForm.validate();
            } else {
                this.$store.dispatch("auth/forgot", this.formData);
                // this.dialog = true
            }
        },
        checkScreenSize() {
            const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
            this.screenSize = isSmallScreen ? "Small" : "Large";
        },

    },

    watch: {

    },
};
</script>

<style>
@import url("../authStyle.css");
</style>