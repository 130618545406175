<template>
  <div class="sub-container">
    <v-card-actions>
      <span class="mt-n3 ml-6">Subscribers</span>
      <v-spacer />
      <v-btn v-if="screenSize === 'Large'" color="#38a4f8" variant="flat" class="text-none" size="small" :to="{
        name: 'importSubs',
        params: { id: routeId },
      }">
        <span class="btn-text">Import subscribers</span>
      </v-btn>
      <v-btn color="#50568e" variant="flat" class="text-none" size="small" @click="redirectToAdd()">
        <span class="btn-text">Add Subscriber</span>
      </v-btn>
    </v-card-actions>

    <v-card-actions class="ml-8">
      <div class="text-center">
        <v-menu v-model="menu2" :close-on-content-click="false" location="end">
          <template v-slot:activator="{ props }">
            <v-chip v-bind="props" class="ml-2"> Status </v-chip>
          </template>

          <v-card min-width="300">
            <v-card-actions>
              <v-checkbox color="indigo-darken-3" label="Subscribed" value="subscribed" class="status-check"
                v-model="subStatus"></v-checkbox>
              <v-checkbox v-model="subStatus" value="unsubscribed" label="Unsubscribed" color="indigo-darken-3"
                class="status-check"></v-checkbox></v-card-actions>
            <v-divider class="mt-n6" />

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn @click="clearFilter()" color="#50568e" variant="text" class="text-none" size="small">
                <span class="menu-btn-text">Clear</span>
              </v-btn>
              <v-btn color="#50568e" variant="flat" @click="filterSubs()" class="text-none" size="small">
                <span class="btn-text">Apply</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </div>

      <v-spacer />
      <div class="search-field">
        <v-text-field v-model="search" label="Search" prepend-inner-icon="mdi-magnify" variant="outlined"
          density="compact" hide-details single-line />
      </div>
    </v-card-actions>
    <v-card class="ml-3">
      <v-data-table :items="checkFilter ? filteredSubs : subscribersByID" :headers="headers" item-value="id"
        :search="search" :items-per-page="5">
        <template #[`item.status`]="{ item }">
          <v-chip :color="getColor(item.selectable.is_subscribed)" variant="flat" size="x-small">
            <span class="chip-text">{{
              item.selectable.is_subscribed ? "subscribed" : "unsubscribed"
            }}</span>
          </v-chip>
        </template>

        <template #[`item.blacklist`]="{ item }">
          <v-chip v-if="item.selectable.is_blacklisted" :color="getBlackColor(item.selectable.is_blacklisted)"
            variant="flat" size="x-small">
            <span class="chip-text">{{
              item.selectable.is_blacklisted ? "blacklisted" : ""
            }}</span>
          </v-chip>
        </template>

        <template #[`item.actions`]="{ item }">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-icon v-bind="props"> mdi-dots-vertical</v-icon>
            </template>

            <v-list>
              <!-- <v-list-item>
                  <v-list-item-title class="list-title"
                    >Add to blacklist</v-list-item-title
                  >
                </v-list-item> -->
              <v-list-item class="mt-n3" :to="{
                name: 'AddSubscriber',
                params: { id: item.selectable.id },
              }">
                <v-list-item-title class="list-title">Edit</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="item.selectable.is_subscribed" class="mt-n3" @click="unSubscribe(item.selectable.id)">
                <v-list-item-title class="list-title">unsubscribe</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="!item.selectable.is_subscribed" class="mt-n3" @click="Subscribe(item.selectable.id)">
                <v-list-item-title class="list-title">Subscribe</v-list-item-title>
              </v-list-item>
              <v-list-item class="mt-n3" @click="Blacklist(item.selectable.id)">
                <v-list-item-title class="list-title">Blacklist</v-list-item-title>
              </v-list-item>
              <v-list-item class="mt-n3" @click="deleteSub(item.selectable.id)">
                <v-list-item-title class="list-title">Delete</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="loaderState" max-width="500">
      <v-card>
        <v-card-actions><v-spacer />Please Wait <v-spacer /></v-card-actions>
        <v-card-actions><v-spacer />
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>

          <v-spacer />
        </v-card-actions>

        <v-progress-linear class="mx-2 mt-n3" color="teal" indeterminate></v-progress-linear>
        <v-card-actions></v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "SubListPage",
  beforeRouteEnter(to, from, next) {
    next(() => { });
  },
  props: {
    subscribersByID: {
      type: Array,
      required: true,
    },
    routeId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      selectedGroup: null,
      subStatus: null,
      menu: false,
      menu2: false,
      menu3: false,
      search: "",
      isValid: false,
      loadingState: false,
      screenSize: "",
      oldValue: false,
      buttonPress: false,
      filteredSubs: [],

      selected: [],

      headers: [
        { title: "Created At", key: "created_at" },
        {
          title: "Name",
          key: "fullName",
          value: (item) => `${item.first_name} ${item.last_name}`,
        },
        { title: "Email", key: "email" },
        {
          title: "Status",
          key: "status",
          value: (item) => `${item.is_subscribed}`,
        },
        {
          title: "BlackList",
          key: "blacklist",
          value: (item) => `${item.is_blacklisted}`,
        },
        { title: "Group name", key: "group_name" },
        { title: "Actions", key: "actions" },
      ],
    };
  },

  computed: {
    lists() {
      return this.$store.getters["subscribers/subscribersGetters"]("Lists");
    },
    subscribers() {
      return this.$store.getters["subscribers/subscribersGetters"](
        "subscribers"
      );
    },
    // filteredSubs() {
    //   return this.$store.getters["subscribers/subscribersGetters"](
    //     "filteredSubs"
    //   );
    // },
    loaderState() {
      return this.$store.getters["subscribers/subscribersGetters"](
        "loaderState"
      );
    },
    checkFilter() {
      return (this.subStatus !== null && this.buttonPress) ||
        (this.selectedGroup !== null && this.buttonPress)
        ? true
        : false;
    },
  },

  mounted() {
    this.checkScreenSize(); // Check screen size initially
    window.addEventListener("resize", this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
      this.screenSize = isSmallScreen ? "Small" : "Large";
    },
    deleteSub(val) {
      alert("Are you sure you want to delete subscriber ?");
      this.$store.dispatch("subscribers/deleteSubscriber", parseInt(val));
    },

    unSubscribe(val) {
      alert("Are you sure you want to unsubscribe subscriber ?");
      const data = {
        subdata: {
          is_subscribed: false,
        },
        id: parseInt(val),
      };
      this.$store.dispatch("subscribers/unsubSubscriber", data);
    },
    Subscribe(val) {
      alert("Are you sure you want to resubscribe subscriber ?");
      const data = {
        subdata: {
          is_subscribed: true,
        },
        id: parseInt(val),
      };
      this.$store.dispatch("subscribers/unsubSubscriber", data);
    },
    Blacklist(val) {
      alert("Are you sure you want to blacklist subscriber ?");
      const data = {
        subdata: {
          is_blacklisted: true,
        },
        id: parseInt(val),
      };
      this.$store.dispatch("subscribers/unsubSubscriber", data);
    },

    getColor(val) {
      if (val) return "#46c35f";
      else return "orange";
    },
    getBlackColor(val) {
      if (val) return "black";
      else return "";
    },
    changeAvatarColor(item) {
      // Change the color of the clicked avatar
      item.avatarProps.color = "red"; // Change to desired color
    },
    redirectToAdd() {
      this.$router.push({ name: "AddSubscriber" });
    },
    filterSubs() {
      this.selectedGroup = null;
      this.buttonPress = true;

      if (this.subStatus === "subscribed") {
        this.filteredSubs = this.subscribersByID.filter(
          (sub) => sub.is_subscribed
        );
        // console.log(this.filteredSubs)
      } else {
        this.filteredSubs = this.subscribersByID.filter(
          (sub) => sub.is_subscribed === false
        );
      }

      this.menu2 = false;
    },
    clearFilter() {
      this.subStatus = null;
      this.selectedGroup = null;
      this.filteredSubs = [];
      this.$store.dispatch("subscribers/getSubscribers");
      this.menu2 = false;
      this.menu3 = false;
      this.buttonPress = false;
    },
  },

  watch: {
    loaderState: {
      handler: function (newValue, oldValue) {
        this.oldValue = oldValue;
        this.loadingState = newValue;
      },
      immediate: true,
    },
  },
};
</script>

<style>
@import url("../subscriberStyle.css");
</style>
