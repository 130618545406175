<template>
  <div class="sub-container">
    <v-card-actions>
      <span class="mt-n3 ml-6 head-">All Templates</span>
      <v-spacer />

      <v-btn
        color="#50568e"
        variant="flat"
        class="text-none"
        size="small"
        :to="{ name: 'AddSubscriber' }"
      >
        <span class="btn-text">Add Template</span>
      </v-btn>
    </v-card-actions>

    <v-card-actions class="ml-8">
      <v-spacer />
      <div class="search-field">
        <v-text-field
          v-model="search"
          label="Search"
          prepend-inner-icon="mdi-magnify"
          variant="outlined"
          density="compact"
          hide-details
          single-line
        />
      </div>
    </v-card-actions>
    <v-row dense>
      <v-col v-for="(variant, i) in templates" :key="i" cols="12" md="4">
        <v-card
          variant="outlined"
          class="mx-auto mt-3"
          color="surface-variant"
          max-width="444"
          height="200px"
        
        >
        <div v-html="variant.content"></div>
          <!-- <template v-slot:actions>
            <v-btn text="Button"></v-btn>
          </template> -->
        </v-card>

        <div class="text-center text-caption">{{ variant.name }}</div>
      </v-col>
    </v-row>

  </div>
  <v-dialog v-model="loaderState" max-width="500">
    <v-card>
      <v-card-actions><v-spacer />Please Wait <v-spacer /></v-card-actions>
      <v-card-actions
        ><v-spacer />
        <div class="ball"></div>
        <div class="ball"></div>
        <div class="ball"></div>
        <div class="ball"></div>
        <div class="ball"></div>

        <v-spacer />
      </v-card-actions>

      <v-progress-linear
        class="mx-2 mt-n3"
        color="teal"
        indeterminate
      ></v-progress-linear>
      <v-card-actions></v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="dialog" max-width="500">
    <v-card>
      <div v-html="tempPreview"></div>
      <v-card-actions>
        <v-spacer />

        <v-btn
          color="#343a40"
          width="80"
          variant="flat"
          @click="dialog = false"
        >
          <span class="btn-text">Close</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AllTemplates",
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("templates/getTemplates");
    });
  },

  data() {
    return {
      groups: [],
      menu: false,
      menu2: false,
      menu3: false,
      search: "",
      isValid: false,
      loadingState: false,
      screenSize: "",
      oldValue: false,
      tempPreview: "",
      dialog: false,

      selected: [],

      headers: [
        { title: "Name", key: "name" },
        { title: "Actions", key: "actions" },
      ],
    };
  },

  computed: {
    templates() {
      return this.$store.getters["templates/templatesGetters"]("Templates");
    },

    loaderState() {
      return this.$store.getters["templates/templatesGetters"]("loaderState");
    },
  },

  mounted() {
    this.checkScreenSize(); // Check screen size initially
    window.addEventListener("resize", this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
      this.screenSize = isSmallScreen ? "Small" : "Large";
    },
    deleteItem(val) {
      alert("Are you sure you want to delete template ?");
      this.$store.dispatch("templates/deleteTemplate", parseInt(val));
    },
    viewItem(val) {
      this.dialog = true;
      this.tempPreview = val;
    },

    getColor(val) {
      if (val) return "#46c35f";
      else return "orange";
    },
    changeAvatarColor(item) {
      // Change the color of the clicked avatar
      item.avatarProps.color = "red"; // Change to desired color
    },
  },

  watch: {
    loaderState: {
      handler: function (newValue, oldValue) {
        this.oldValue = oldValue;
        this.loadingState = newValue;
      },
      immediate: true,
    },
  },
};
</script>

<style>
@import url("../templatesStyle.css");
</style>
