<template>
  <!-- <div class="stats-container"> -->
    <v-container fluid class="stats-page">
  
    <h3 class="mt-n3 ml-6">Dashboard</h3>
    <v-row class="mt-n3 "
      ><v-col cols="12" md="4" sm="12">
        <v-row class="mt-6">
          <v-sheet
            variant="flat"
            rounded="lg"
            elevation="0"
            height="100"
            width="500"
          >
            <v-row>
              <v-avatar class="ml-8 mt-8" size="54" color="#50568e" rounded="">
                <v-icon color="white" icon="mdi-vanish"></v-icon>
              </v-avatar>
              <span class="ml-3 mt-8">
                <div class="stats-text">  {{ stats ? stats.total_campaigns : "N/A" }}</div>
  
                <span class="campaign-text">Total Campaigns</span>
              </span>
            </v-row>
          </v-sheet></v-row
        >
        <v-row class="mt-6">
          <v-sheet
            variant="flat"
            rounded="lg"
            elevation="0"
            height="100"
            width="500"
          >
            <v-row>
              <v-avatar class="ml-8 mt-8" size="54" color="#50568e" rounded="">
                <v-icon color="white" icon="mdi-tooltip"></v-icon>
              </v-avatar>
              <span class="ml-3 mt-8">
                <div class="stats-text">{{ stats ? stats.total_subscribers : "N/A" }}</div>
    
                <span class="campaign-text">Total Subscribers</span>
              </span>
            </v-row>
          </v-sheet></v-row
        >
        <v-row class="mt-6">
          <v-sheet
            variant="flat"
            rounded="lg"
            elevation="0"
            height="100"
            width="500"
          >
            <v-row>
              <v-avatar class="ml-8 mt-8" size="54" color="#50568e" rounded="">
                <v-icon color="white" icon="mdi-view-sequential"></v-icon>
              </v-avatar>
              <span class="ml-3 mt-8">
                <div class="stats-text">{{ stats ? stats.total_unsubscribers : "N/A" }}</div>
    
                <span class="campaign-text">Total unsubscribers</span>
              </span>
            </v-row>
          </v-sheet></v-row
        > </v-col
      ><v-col cols="12" md="8" sm="12">
        <v-card class="graph-card" variant="flat">
       
            <CanvasJSChart :options="options" />
        
        </v-card>
        </v-col
    ></v-row>
    
    <v-row class="mt-6">
      <v-col :cols="6" :md="2" :sm="2">
        <v-sheet
          variant="flat"
          rounded="lg"
          class="d-flex align-center"
          elevation="0"
          height="100"
        >
          <span class="ml-6">
            <div class="stats-text">
              {{ stats ? stats.total_number_sent : "N/A" }}
            </div>
            <span class="campaign-text">Total sent</span>
          </span>
        </v-sheet>
        </v-col
      >
      <v-col :cols="6" :md="2" :sm="2" >
        <v-sheet
          variant="flat"
          rounded="lg"
          class="d-flex align-center"
          elevation="0"
          height="100"
        >
          <span class="ml-6">
            <div class="stats-text">
              {{ stats ? stats.total_number_delivered : "N/A" }}
            </div>
            <span class="campaign-text">Total deliveries</span>
          </span>
        </v-sheet></v-col
      >
      <v-col :cols="6" :md="2" :sm="2">
        <v-sheet
          variant="flat"
          rounded="lg"
          class="d-flex align-center"
          elevation="0"
          height="100"
        >
          <span class="ml-6">
            <div class="stats-text">
              
              {{ stats ? stats.total_bounces : "N/A" }}
            </div>
            <span class="campaign-text">Total bounces</span>
          </span>
        </v-sheet></v-col
      >
      <v-col :cols="6" :md="2" :sm="2">
        <v-sheet
          variant="flat"
          rounded="lg"
          class="d-flex align-center"
          elevation="0"
          height="100"
        >
          <span class="ml-6">
            <div class="stats-text">
              {{ stats ? stats.total_number_opened : "N/A" }}
            </div>
            <span class="campaign-text">Total opens</span>
          </span>
        </v-sheet></v-col
      >
      <v-col :cols="6" :md="2" :sm="2">
        <v-sheet
          variant="flat"
          rounded="lg"
          class="d-flex align-center"
          elevation="0"
          height="100"
        >
          <span class="ml-6">
            <div class="stats-text">
              {{ stats ? stats.total_number_clicked : "N/A" }}
            </div>
            <span class="campaign-text">Total clicks</span>
          </span>
        </v-sheet></v-col
      >
      <v-col :cols="6" :md="2" :sm="2">
        <v-sheet
          variant="flat"
          rounded="lg"
          class="d-flex align-center"
          elevation="0"
          height="100"
        >
          <span class="ml-6">
            <div class="stats-text">{{ stats ? stats.total_unsubscribers : "N/A" }}</div>
            <span class="campaign-text">Total unsubscribers</span>
          </span>
        </v-sheet></v-col
      >
    </v-row>
    <v-row class="mt-6"
      ><v-col :cols="6" :md="2" :sm="2">
        <v-sheet
          variant="flat"
          rounded="lg"
          class="d-flex flex-column justify-center align-center"
          elevation="0"
          height="150"
        >
          <div class="text-center">
            <v-progress-circular
              :model-value="value"
              :rotate="360"
              :size="80"
              :width="3"
              color="#50568e"
            >
              <template v-slot:default
                >{{ stats ? stats.average_bounce_rate : 0 }}%</template
              >
            </v-progress-circular>
          </div>
          <div class="text-center mt-2 campaign-text">Bounce rate</div>
        </v-sheet>
      </v-col>
      <v-col :cols="6" :md="2" :sm="2">
        <v-sheet
          variant="flat"
          rounded="lg"
          class="d-flex flex-column justify-center align-center"
          elevation="0"
          height="150"
        >
          <div class="text-center">
            <v-progress-circular
              :model-value="value"
              :rotate="360"
              :size="80"
              :width="3"
              color="#50568e"
            >
              <template v-slot:default
                >{{ stats ? stats.average_click_rate : 0 }}%</template
              >
            </v-progress-circular>
          </div>
          <div class="text-center mt-2 campaign-text">Click rate</div>
        </v-sheet></v-col
      >
      <v-col :cols="6" :md="2" :sm="2">
        <v-sheet
          variant="flat"
          rounded="lg"
          class="d-flex flex-column justify-center align-center"
          elevation="0"
          height="150"
        >
          <div class="text-center">
            <v-progress-circular
              :model-value="value"
              :rotate="360"
              :size="80"
              :width="3"
              color="#50568e"
            >
              <template v-slot:default
                >{{ stats ? stats.average_open_rate : 0 }}%</template
              >
            </v-progress-circular>
          </div>
          <div class="text-center mt-2 campaign-text">Open rate</div>
        </v-sheet></v-col
      >
      <v-col :cols="6" :md="2" :sm="2">
        <v-sheet
          variant="flat"
          rounded="lg"
          class="d-flex flex-column justify-center align-center"
          elevation="0"
          height="150"
        >
          <div class="text-center">
            <v-progress-circular
              :model-value="value"
              :rotate="360"
              :size="80"
              :width="3"
              color="#50568e"
            >
              <template v-slot:default>{{ stats ? stats.sending_rate : "N/A" }}%</template>
            </v-progress-circular>

 
          </div>
          <div class="text-center mt-2 campaign-text">Sending rate</div>
        </v-sheet></v-col
      >
      <v-col :cols="6" :md="2" :sm="2">
        <v-sheet
          variant="flat"
          rounded="lg"
          class="d-flex flex-column justify-center align-center"
          elevation="0"
          height="150"
        >
          <div class="text-center">
            <v-progress-circular
              :model-value="value"
              :rotate="360"
              :size="80"
              :width="3"
              color="#50568e"
            >
              <template v-slot:default>{{ stats ? stats.delivery_rate : "N/A" }}%</template>
            </v-progress-circular>
          </div>
          <div class="text-center mt-2 campaign-text">Delivery rate</div>
        </v-sheet></v-col
      >
      <v-col :cols="6" :md="2" :sm="2">
        <v-sheet
          variant="flat"
          rounded="lg"
          class="d-flex flex-column justify-center align-center"
          elevation="0"
          height="150"
        >
          <div class="text-center">
            <v-progress-circular
              :model-value="value"
              :rotate="360"
              :size="80"
              :width="3"
              color="#50568e"
            >
              <template v-slot:default>{{ stats ? stats.unsubscribe_rate : "N/A" }}%</template>
            </v-progress-circular>
          </div>
          <div class="text-center mt-2 campaign-text">unsubscribe rate</div>
        </v-sheet></v-col
      >
    </v-row>
  </v-container>
    
 
</template>

<script>
export default {
  name: "StatsPage",
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("dashboard/getStats");
    });
  },

  data() {
    return {
      screenSize: "",
      value: 0,
      chart: null,
    };
  },

  computed: {
    stats() {
      return this.$store.getters["dashboard/dashboardGetters"]("stats");
    },
    options() {
  return {
    animationEnabled: true,
    exportEnabled: true,
    axisX: {
      gridThickness: 0
    },
    axisY: {
      gridThickness: 0
    },
    data: [
      {
        type: "bar",
        dataPoints: [
          { label: "Total Number Sent", y: this.stats.total_number_sent },
          {
            label: "Total Number Delivered",
            y: this.stats.total_number_delivered,
          },
          {
            label: "Total Number Opened",
            y: this.stats.total_number_opened,
          },
          {
            label: "Total Number Clicked",
            y: this.stats.total_number_clicked,
          },
          {
            label: "Average Bounce Rate",
            y: this.stats.average_bounce_rate,
          },
          { label: "Average Open Rate", y: this.stats.average_open_rate },
          { label: "Average Click Rate", y: this.stats.average_click_rate },
        ],
      },
    ],
  };
},

  },

  mounted() {
    this.checkScreenSize(); // Check screen size initially
    window.addEventListener("resize", this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
      this.screenSize = isSmallScreen ? "Small" : "Large";
    },
  },

  watch: {},
};
</script>

<style></style>
