import call from "@/service/http";
import constants from "./dashboardConstants";
import eventBus from "@/utils/eventBus";
// import AuthService from "../auth/views/authService";

const dashboardModule = {
  namespaced: true,
  state: {
    stats:[],
    profile:{}
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.value;
    },
  },
  getters: {
    dashboardGetters: (state) => (val) => state[val],
  },
  actions: {
    getStats: ({ commit }) => {
      call("get", constants.stats)
        .then((res) => {
          if (res.data.status_code === 200) {
            commit("MUTATE", { state: "stats", value: res.data.data });
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: res.data.message,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getProDetails: ({ commit }) => {
      call("get", constants.prof)
        .then((res) => {
          commit("MUTATE", { state: "profile", value: res.data.data });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};

export default dashboardModule;
