<template>
  <v-container fluid class="container">
    <v-card variant="tonal" class="mx-auto align-center login-card" height="600" max-width="550"
      color="surface-variant">
      <div class="main-card"></div>
      <v-img class="mx-auto" height="70" max-width="170" src="./main.png"></v-img>
      <div class="text-center mt-3">
        <!-- Wrap "Hi There" in a div and add text-center class -->
        <span class="main-text">Hi There</span><br />
        <span class="main-text">Login to your dashboard</span>
      </div>
      <v-card-text class="login-form">
        <v-form ref="loginForm" v-model="isValid">
          <!--User name-->
          <span>Email</span>
          <v-text-field class="mt-2" ref="email" :rules="rules.required" v-model="formData.email" density="compact"
            placeholder="Enter your Email" style="color: #3c3731" variant="outlined" />
          <!-- Password -->
          <span>Password</span>
          <v-text-field class="mt-2" ref="password" :rules="rules.password" v-model="formData.password"
            density="compact" placeholder="Enter your Password" :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
            :type="visible ? 'text' : 'password'" @click:append-inner="visible = !visible" style="color: #3c3731"
            variant="outlined" />
        </v-form>
      </v-card-text>
      <v-card-actions class="mt-n6">
        <v-btn @click="Login()" block class="text-none text-subtitle-1" color="#50568e" elevation="4" variant="flat">
          <span class="login--text" v-if="!loginState">Login</span>
          <v-progress-circular :width="3" color="#50568e" indeterminate v-if="loginState"></v-progress-circular>
        </v-btn>
      </v-card-actions>
      <v-card-actions class="mt-0">
        <v-spacer />
        <v-btn :to="{ name: 'forgot' }" text class="text-none text-subtitle-1" color="#50568e" variant="text">
          <v-icon>mdi-lock</v-icon><span class="forgot--text">Forgot Password</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>

export default {
  name: "testPage",


  data() {
    return {
      isValid: false,
      loginState: false,
      screenSize: "",
      visible: false,
      formData: {
        email: "",
        password: "",
      },
      showPassword: false,
      rules: {
        required: [
          (value) => !!value || "Required."
        ],
        email: [
          (value) => !!value || "Required.",
          (value) => /.+@.+\..+/.test(value) || "Invalid email address."
        ],
        password: [(value) => !!value || "Required."],
      },
    };
  },

  computed: {

  },

  mounted() {
    this.checkScreenSize(); // Check screen size initially
    window.addEventListener("resize", this.checkScreenSize);
  },
  methods: {
    Login() {
      if (!this.isValid) {
        this.$refs.loginForm.validate();
      } else {
        this.loginState = true;
        this.$store.dispatch("auth/login", this.formData);
      }
    },
    checkScreenSize() {
      const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
      this.screenSize = isSmallScreen ? "Small" : "Large";
    },
    redirect() {
      this.$router.push({ name: "test" });
    },

  },

  watch: {

  },
};
</script>

<style>
@import url("../authStyle.css");
</style>
