// import addSubscriber from "../subscribers/components/addSubscriber";

import dashboard from "../dashboard/views/dashboard";
import ListPage from "../lists/components/listDisplay";
import AddList from "../lists/components/addlist";

const listRoutes = [
  {
    path: "/",
    component: dashboard,
    meta: { requiresAuth: true },
    children: [
      {
        path: "add-list/:id?",
        name: "AddList",
        component: AddList,
        meta: { requiresAuth: true },
      },
      {
        path: "all-lists",
        name: "ListPage",
        component: ListPage,
        meta: { requiresAuth: true },
      },
    ],
  },
];

export default listRoutes;
