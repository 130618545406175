// import addSubscriber from "../subscribers/components/addSubscriber";

import dashboard from "../dashboard/views/dashboard";
import addUser from "../roles/components/addUser.vue";
import addCompany from "../roles/components/addCompany.vue";
import Companies from "../roles/components/Companies.vue";

const listRoutes = [
  {
    path: "/",
    component: dashboard,
    meta: { requiresAuth: true },
    children: [
      {
        path: "invite-user/",
        name: "addUser",
        component: addUser,
        meta: { requiresAuth: true },
      },

      {
        path: "add-company/:id?",
        name: "addCompany",
        component: addCompany,
        meta: { requiresAuth: true },
      },
    {
        path: "all-companies",
        name: "Companies",
        component: Companies,
        meta: { requiresAuth: true },
      },
  
    ],
  },
];

export default listRoutes;
