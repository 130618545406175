<template>
  <div class="sub-container">
    <v-card-actions>
      <span class="mt-n3 ml-6 head-sub-text">All Emails</span>
      <v-spacer />
    </v-card-actions>

    <v-card-actions class="ml-8">
      <v-spacer />
      <div class="search-field">
        <v-text-field
          v-model="search"
          label="Search"
          prepend-inner-icon="mdi-magnify"
          variant="outlined"
          density="compact"
          hide-details
          single-line
        />
      </div>
    </v-card-actions>
    <v-card class="ml-3">
      <v-data-table
        :items="emails.length > 0 ? emails : []"
        :headers="headers"
        item-value="id"
        :search="search"
      >
        <template #[`item.campaign_name`]="{ item }">
          {{ item.selectable.campaign_name !== undefined ? item.selectable.campaign_name : "Test " }}
        </template>

        <template #[`item.is_sent`]="{ item }">
          <v-chip
            :color="getColor(item.selectable.is_sent)"
            variant="flat"
            size="x-small"
          >
            <span class="chip-text">{{
              item.selectable.is_sent ? "sent" : "not sent"
            }}</span>
          </v-chip>
        </template>

        <template #[`item.is_clicked`]="{ item }">
          <v-chip
            :color="getColor(item.selectable.is_clicked)"
            variant="flat"
            size="x-small"
          >
            <span class="chip-text">{{
              item.selectable.is_clicked ? "clicked" : "not clicked"
            }}</span>
          </v-chip>
        </template>

        <template #[`item.is_opened`]="{ item }">
          <v-chip
            :color="getColor(item.selectable.is_opened)"
            variant="flat"
            size="x-small"
          >
            <span class="chip-text">{{
              item.selectable.is_opened ? "opened" : "unopened"
            }}</span>
          </v-chip>
        </template>

        <template #[`item.is_delivered`]="{ item }">
          <v-chip
            :color="getColor(item.selectable.is_delivered)"
            variant="flat"
            size="x-small"
          >
            <span class="chip-text">{{
              item.selectable.is_delivered ? "delivered" : "undelivered"
            }}</span>
          </v-chip>
        </template>

        <template #[`item.actions`]="{ item }">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-icon v-bind="props"> mdi-dots-vertical</v-icon>
            </template>

            <v-list>
              <v-list-item
                class="mt-n3"
                :to="{ name: 'AddList', params: { id: item.selectable.id } }"
              >
                <v-list-item-title class="list-title">Edit</v-list-item-title>
              </v-list-item>

              <v-list-item
                class="mt-n3"
                @click="deleteItem(item.selectable.id)"
              >
                <v-list-item-title class="list-title">Delete</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="loaderState" max-width="500">
      <v-card>
        <v-card-actions><v-spacer />Please Wait <v-spacer /></v-card-actions>
        <v-card-actions
          ><v-spacer />
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>

          <v-spacer />
        </v-card-actions>

        <v-progress-linear
          class="mx-2 mt-n3"
          color="teal"
          indeterminate
        ></v-progress-linear>
        <v-card-actions></v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "ListDisplayPage",
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("email/getEmails");
    });
  },

  data() {
    return {
      dialog: true,
      groups: [],
      menu: false,
      menu2: false,
      menu3: false,
      search: "",
      isValid: false,
      loadingState: false,
      screenSize: "",
      oldValue: false,

      selected: [],

      headers: [
        {
          title: "Campaign",
          key: "campaign_name",
        },
        { title: "Recipient", key: "to_email" },

        {
          title: "Sent",
          key: "is_sent",
        },
        {
          title: "Opened",
          key: "is_opened",
        },
        {
          title: "Delieverd",
          key: "is_delivered",
        },
        {
          title: "Clicked",
          key: "is_clicked",
        },
        // {
        //     title: "No of Subscribers",
        //     key: "status",

        // },
        // { title: "Actions", key: "actions" },
      ],
    };
  },

  computed: {
    emails() {
      return this.$store.getters["email/emailGetters"]("emails");
    },
    subscribers() {
      return this.$store.getters["subscribers/subscribersGetters"](
        "subscribers"
      );
    },
    loaderState() {
      return this.$store.getters["email/emailGetters"]("loaderState");
    },
  },

  mounted() {
    this.checkScreenSize(); // Check screen size initially
    window.addEventListener("resize", this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
      this.screenSize = isSmallScreen ? "Small" : "Large";
    },
    deleteItem(val) {
      alert("Are you sure you want to delete list ?");
      this.$store.dispatch("list/deleteList", parseInt(val));
    },

    getColor(val) {
      if (val) return "#46c35f";
      else return "orange";
    },
    changeAvatarColor(item) {
      // Change the color of the clicked avatar
      item.avatarProps.color = "red"; // Change to desired color
    },
  },

  watch: {
    loaderState: {
      handler: function (newValue, oldValue) {
        this.oldValue = oldValue;
        this.loadingState = newValue;
      },
      immediate: true,
    },
  },
};
</script>

<style>
@import url("../emailStyle.css");
</style>
