import { createRouter, createWebHistory } from 'vue-router';
import AuthRoutes from '@/modules/auth/authRoutes';
import DashboardRoutes from '@/modules/dashboard/dashboardRoutes';
import subscribersRoutes from '@/modules/subscribers/subscribersRoutes';
import listRoutes from '@/modules/lists/listRoutes';
import templatesRoutes from '@/modules/templates/templatesRoutes';
import campaignRoutes from '@/modules/campaigns/campaignRoutes';
import emailRoutes from '@/modules/emails/emailRoutes';
import rolesRoutes from '@/modules/roles/rolesRoutes';
import AuthService from "@/modules/auth/views/authService";

const routes = [
  ...AuthRoutes,
  ...DashboardRoutes,
  ...subscribersRoutes,
  ...listRoutes,
  ...templatesRoutes,
  ...campaignRoutes,
  ...emailRoutes,
  ...rolesRoutes
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // Reset page to top
  window.scrollTo(0, 0);

  if (to.meta.requiresAuth && !AuthService.check()) {
    next({ name: 'signIn' });
  } else {
    next();
  }
});

export default router;
