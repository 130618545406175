<template>
  <div class="sub-container">
    <span class="mt-n3 ml-6 head-sub-text">
      {{ routeParams ? "Edit" : "Add" }} Template</span>

    <v-card class="mx-6 mt-6">
      <v-card-text>
        <v-form ref="subForm" v-model="isValid">
          <v-row class="mt-n6"><v-col class="mx-3 mt-3" :cols="12" :md="2" :sm="3">Template name</v-col><v-col
              :cols="12" :md="6" :sm="3" align-self="start"><v-text-field class="mt-2 mx-auto" ref="fname"
                :rules="rules.required" v-model="formData.name" density="compact" style="color: #3c3731"
                variant="outlined" /></v-col></v-row>

          <v-card-actions class="ml-6">
            <v-btn color="red" variant="text" class="text-none">
              <span class="var-btn-text">{{ subscriberName }} </span>
            </v-btn>
            <!-- <v-btn color="red" variant="flat" @click="exportHtml">
              <span class="btn-text">Export HTML</span>
            </v-btn> -->
          </v-card-actions>

          <v-btn-toggle class="mb-6" v-model="text" variant="outlined" color="deep-purple-accent-6" rounded="0" group>
            <v-btn color="blue" value="normal"  :size="screenSize === 'Large' ? 'small' : 'x-small'"> Normal Editor </v-btn>

            <v-btn color="blue" :size="screenSize === 'Large' ? 'small' : 'x-small'" value="unlayer"> Unlayer Editor </v-btn>
          </v-btn-toggle>
          <QuillEditor v-if="text === 'normal'" theme="snow" class="quill-editor mt-6" v-model:content="normalEditor"
            contentType="html" :options="editorOptions" />

          <EmailEditor  v-if="text === 'unlayer' " ref="emailEditor" class="vunlayer-editor" :projectId="projectId"
            v-on:load="editorLoaded" v-on:ready="editorReady" />
        </v-form>
      </v-card-text>

      <v-card-actions class="ml-6">
        <v-btn @click="routeParams ? editHtml() : exportHtml()" color="#50568e" width="80" variant="flat">
          <span class="btn-text" v-if="!loadingState">Save</span>
          <v-progress-circular :width="3" color="#50568e" indeterminate v-if="loadingState"></v-progress-circular>
        </v-btn>

        <v-btn color="#343a40" width="80" variant="flat" :to="{ name: 'templateList' }">
          <span class="btn-text">Cancel</span>
        </v-btn>

        <!-- <v-btn color="green" variant="flat" @click="saveDesign">
          <span class="btn-text">Save Design</span>
        </v-btn>
        <v-btn color="red" variant="flat" @click="loadDesign">
          <span class="btn-text">Load Design</span>
        </v-btn> -->

        <!-- <v-btn color="red" variant="flat" @click="exportHtml">
          <span class="btn-text">Export HTML</span>
        </v-btn> -->
      </v-card-actions>
    </v-card>

    <v-dialog v-model="loaderState" max-width="500">
      <v-card>
        <v-card-actions><v-spacer />Please Wait <v-spacer /></v-card-actions>
        <v-card-actions><v-spacer />
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>

          <v-spacer />
        </v-card-actions>

        <v-progress-linear class="mx-2 mt-n3" color="teal" indeterminate></v-progress-linear>
        <v-card-actions></v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { EmailEditor } from "vue-email-editor";
import eventBus from "@/utils/eventBus";
import { QuillEditor } from "@vueup/vue-quill";
export default {
  name: "addTemplate",
  components: {
    EmailEditor,
    QuillEditor,
    // Editor
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      if (v.$route.params.id !== "") {
        v.$store.dispatch(
          "templates/getSingleTemplate",
          parseInt(v.$route.params.id)
        );
      }
    });
  },

  data() {
    return {
      text: "normal",
      loading: false,
      textareaValue: "",
      textareaContent: "",
      cursorPosition: 0,
      subscriberName: "{{name}}",
      minHeight: "1000px",
      locale: "en",
      projectId: 106879,
      tools: {
        image: {
          enabled: false,
        },
      },
      editorOptions: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          ['link', 'image', 'video'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'indent': '-1' }, { 'indent': '+1' }],
          [{ 'align': [] }],
          ['clean'],
          ['html'] // Add the custom HTML button
        ],
      },

      isValid: false,
      loadingState: false,
      screenSize: "",
      oldValue: false,
      hasAlreadyLoaded: false,
      test: [],
      normalEditor: "",
      formData: {
        name: "",
        content: "",
        json_data: {},
      },

      rules: {
        required: [(value) => !!value || "Required."],
        email: [
          (value) => !!value || "Required.",
          (value) => /.+@.+\..+/.test(value) || "Invalid email address.",
        ],
        password: [(value) => !!value || "Required."],
      },
      jsonData: null,
    };
  },

  computed: {
    loaderState() {
      return this.$store.getters["templates/templatesGetters"]("loaderState");
    },

    singleTemplate() {
      return this.$store.getters["templates/templatesGetters"]("template");
    },

    routeParams() {
      const id = this.$route.params.id;
      if (id !== "" && id !== "NaN" && id !== undefined) {
        return true;
      } else {
        return false;
      }
    },

    routeParamsId() {
      return this.$route.params.id !== ""
    },
    testId() {
      return this.$route.params.id
    },
  },

  mounted() {
    this.checkScreenSize(); // Check screen size initially
    window.addEventListener("resize", this.checkScreenSize);

    eventBus.on("creationSuccess", () => {
      this.$router.push({ name: "templateList" });
    });
  },

  methods: {
    editorReady() {
      this.loading = true;
    },
    editorLoaded() {
      this.hasAlreadyLoaded = true;
    },
    redirectPage() {
      this.$router.push({ name: "subscriberList" });
    },

    saveDesign() {
      this.$refs.emailEditor.editor.saveDesign((design) => {
        this.formData.json_data = { ...design };
      });
    },

    loadDesign(val) {
      try {
        // Check if jsonData is serializable
        const stringifiedData = JSON.stringify(val);
        const parsedData = JSON.parse(stringifiedData);

        this.$refs.emailEditor.editor.loadDesign(parsedData);
      } catch (error) {
        console.error("Serialization error:", error);
      }
    },

    checkScreenSize() {
      const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
      this.screenSize = isSmallScreen ? "Small" : "Large";
    },
    exportHtml() {
      if (this.text === "normal") {
        this.save();
      } else {
        this.saveDesign();
        this.$refs.emailEditor.editor.exportHtml((data) => {
          this.formData.content = data.html;
          this.save();
        });
      }
    },
    save() {
      if (!this.isValid) {
        this.$refs.subForm.validate();
      } else {
        if (this.text === "normal") {
          this.formData.content = this.normalEditor;
        }
        this.$store.dispatch("templates/saveTemplate", this.formData);
      }
    },

    edit() {
      if (!this.isValid) {
        this.$refs.subForm.validate();
      } else {
        if (this.text === "normal") {
          this.formData.content = this.normalEditor;
        }
        const data = {
          alldata: this.formData,
          id: parseInt(this.$route.params.id),
        };
        this.$store.dispatch("templates/editTemplate", data);
      }
    },
    editHtml() {
      if (this.text === "normal") {
        this.edit();
      } else {
        this.saveDesign();
        this.$refs.emailEditor.editor.exportHtml((data) => {
          this.formData.content = data.html;

          this.edit();
        });
      }
    },
    insertTextAtCursor(text) {
      // Accessing the EmailEditor component instance
      const emailEditor = this.$refs.emailEditor;
      console.log(emailEditor);

      // Checking if the EmailEditor has a method or a way to access its underlying textarea
      if (emailEditor && emailEditor.textareaElement) {
        const textarea = emailEditor.textareaElement;
        const startPos = textarea.selectionStart;
        const endPos = textarea.selectionEnd;

        // Insert the text at the cursor position
        textarea.value =
          textarea.value.substring(0, startPos) +
          text +
          textarea.value.substring(endPos);

        // Move the cursor to the end of the inserted text
        textarea.selectionStart = startPos + text.length;
        textarea.selectionEnd = startPos + text.length;
      } else {
        console.error("Failed to access the EmailEditor textarea element.");
      }
    },
  },

  watch: {
    loaderState: {
      handler: function (newValue, oldValue) {
        this.oldValue = oldValue;
        this.loadingState = newValue;
      },
      immediate: true,
    },
    singleTemplate: {
      handler: function () {
        if (this.singleTemplate !== "undefined") {
          this.formData = { ...this.singleTemplate };
        }
        if (!this.singleTemplate.content.startsWith("<!DOCTYPE HTML PUBLIC")) {
          this.normalEditor = this.singleTemplate.content;
          this.text = 'normal'
        } else {
          this.text = "unlayer"
        }
      },
    },
    loading: {
      handler: function () {
        if (this.loading) {
          this.loadDesign(this.singleTemplate.json_data);
          this.formData.name = this.singleTemplate.name;
        }
      },
    },
  },
};
</script>

<style>
@import url("../templatesStyle.css");
</style>
