<template>
    <div class="sub-container">
      <v-card-actions>
        <span class="mt-n3 ml-6 head-sub-text">All Companies</span>
        <v-spacer />
  
        <v-btn color="#50568e" variant="flat" class="text-none" size="small" :to="{ name: 'addCompany' }">
          <span class="btn-text">Add Company</span>
        </v-btn>
      </v-card-actions>
  
      <v-card-actions class="ml-8">
      
 
  
        <v-spacer />
        <div class="search-field">
          <v-text-field v-model="search" label="Search" prepend-inner-icon="mdi-magnify" variant="outlined"
            density="compact" hide-details single-line />
        </div>
      </v-card-actions>
      <v-card class="ml-3">
        <v-data-table :items="companies.length > 0 ? companies : []" :headers="headers" item-value="id"
          :search="search">
            <template #[`item.allowed_senders`]="{ item }">
                   {{ item.selectable.allowed_senders[0] }}
                </template>
       
  
          <template #[`item.actions`]="{ item }">
            <v-menu>
              <template v-slot:activator="{ props }">
                <v-icon v-bind="props"> mdi-dots-vertical</v-icon>
              </template>
  
              <v-list>
                <v-list-item class="mt-n3" :to="{ name: 'AddList', params: { id: item.selectable.id } }">
                  <v-list-item-title class="list-title">Edit</v-list-item-title>
                </v-list-item>
  
                <!-- <v-list-item class="mt-n3" @click="deleteItem(item.selectable.id)">
                  <v-list-item-title class="list-title">Delete</v-list-item-title>
                </v-list-item> -->
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>
      <v-dialog v-model="loaderState" max-width="500">
        <v-card>
  
          <v-card-actions><v-spacer />Please Wait <v-spacer /></v-card-actions>
          <v-card-actions><v-spacer />
            <div class="ball"></div>
            <div class="ball"></div>
            <div class="ball"></div>
            <div class="ball"></div>
            <div class="ball"></div>
  
            <v-spacer />
          </v-card-actions>
  
  
          <v-progress-linear class="mx-2 mt-n3" color="teal" indeterminate></v-progress-linear>
          <v-card-actions></v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
  export default {
    name: "CompaniesPage",
    beforeRouteEnter(to, from, next) {
      next((v) => {
        v.$store.dispatch("roles/getCompanies");
    
      });
    },
  
    data() {
      return {
        dialog: true,
        groups: [],
        menu: false,
        menu2: false,
        menu3: false,
        search: "",
        isValid: false,
        loadingState: false,
        screenSize: "",
        oldValue: false,
  
        selected: [],
  
        headers: [
          {
            title: "Name",
            key: "name",
          },
          { title: "Domain", key: "domain" },
          {
              title: "Allowed senders",
              key: "allowed_senders",
  
          },
       
          { title: "Actions", key: "actions" },
        ],
      };
    },
  
    computed: {
        companies() {
        return this.$store.getters["roles/rolesGetters"]("companies");
      },
      subscribers() {
        return this.$store.getters["subscribers/subscribersGetters"](
          "subscribers"
        );
      },
      loaderState() {
        return this.$store.getters["roles/rolesGetters"](
          "loaderState"
        );
      },
    },
  
    mounted() {
      this.checkScreenSize(); // Check screen size initially
      window.addEventListener("resize", this.checkScreenSize);
    },
    methods: {
      checkScreenSize() {
        const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
        this.screenSize = isSmallScreen ? "Small" : "Large";
      },
      deleteItem(val) {
        alert("Are you sure you want to delete list ?");
        this.$store.dispatch("list/deleteList", parseInt(val));
      },
  
      getColor(val) {
        if (val) return "#46c35f";
        else return "orange";
      },
      changeAvatarColor(item) {
        // Change the color of the clicked avatar
        item.avatarProps.color = "red"; // Change to desired color
      },
    },
  
    watch: {
      loaderState: {
        handler: function (newValue, oldValue) {
          this.oldValue = oldValue;
          this.loadingState = newValue;
        },
        immediate: true,
      },
    },
  };
  </script>
  
  <style>
  @import url("../rolesStyles.css");
  </style>
  