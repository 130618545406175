// ToastPlugin.js
import { createApp } from 'vue';
import ToastComponent from './Toast.vue';

const ToastPlugin = {
  install(app) {
    const root = document.createElement('div');
    document.body.appendChild(root);

    const appInstance = createApp(ToastComponent);
    const vm = appInstance.mount(root);

    app.config.globalProperties.$toast = {
      error(message) {
        vm.showToast({ type: 'error', message });
      },
      info(message) {
        vm.showToast({ type: 'info', message });
      },
      success(message) {
        vm.showToast({ type: 'success', message });
      },
    };
  },
};

export default ToastPlugin;
