<template>
  <v-card variant="outlined" class="import-card">
    <v-card-actions class="mt-4">
      <v-btn color="blue" variant="flat" class="mx-6 mt-6" size="small" :to="{
        name: 'subscriberList',
      }">
        <v-icon>mdi-arrow-left</v-icon> <span class="btn-text">Back</span>
      </v-btn>
      <v-spacer />
    </v-card-actions>
    <v-card-actions class="mt-6">
      <span class="mt-n3 ml-6 head-text">Upload CSV</span>
      <v-spacer />
    </v-card-actions>
    <v-alert type="info" variant="tonal" class="mx-6">
      <p>- Format your CSV the same way as the sample file below.</p>
      <br />

      <!-- <p>
      - If you want to download a sample with custom fields then click on the
      checkbox below and download. Otherwise, simply download the default file.>
    </p>
    <br /> -->

      <!-- <p>
      - Your CSV columns should be separated by commas, not semi-colons or any
      other characters<br />
    </p>
    <br /> -->

      <p>
        - The number of columns in your CSV should be the same as the example
        below<br />
      </p>
      <br />

      <p>
        - Valid fields are case sensitive, Tip: make sure name of fields must be
        following: Email,First Name, Last Name <br />
      </p>
      <br />
    </v-alert>

    <div class="mt-8 ml-6">
      <a class="sample-download" href="./recipients.xlsx" download="sample.xlsx">
        Download Sample Excel File
      </a>
    </div>
    <v-card variant="flat" class="mx-auto" max-width="1200">
      <v-autocomplete class="mt-6 mx-16" v-model="group" :items="lists.length > 0 ? lists : []"
        color="blue-grey-lighten-2" item-title="name" item-value="id" label="Select" chips closable-chips
        density="compact" :rules="rules.required" style="color: #3c3731" variant="outlined"></v-autocomplete>
      <v-file-input v-model="files" :show-size="1000" class="mt-6 mx-16" color="deep-purple-accent-4" label="File input"
        accept=".xlsx,.csv,.txt" placeholder="Select your files" prepend-icon="mdi-paperclip" variant="outlined"
        density="compact" :rules="rules.required" counter @change="handleFileUpload">
        <template v-slot:selection="{ fileNames }">
          <template v-for="(fileName, index) in fileNames" :key="fileName">
            <v-chip v-if="index < 2" class="me-2" color="deep-purple-accent-4" size="small" label>
              {{ fileName }}
            </v-chip>

            <span v-else-if="index === 2" class="text-overline text-grey-darken-3 mx-2">
              +{{ files.length - 2 }} File(s)
            </span>
          </template>
        </template>
      </v-file-input>
    </v-card>

    <v-card-actions>
      <v-spacer />
      <v-btn color="#50568e" @click="uploadFile()" variant="flat" class="mx-6 mt-6" size="small" width="300px">
        <span class="btn-text">Upload</span>
      </v-btn>
      <v-spacer />
    </v-card-actions>

    <v-dialog v-model="loaderState" max-width="500">
      <v-card>
        <v-card-actions><v-spacer />Please Wait <v-spacer /></v-card-actions>
        <v-card-actions><v-spacer />
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>
          <div class="ball"></div>

          <v-spacer />
        </v-card-actions>

        <v-progress-linear class="mx-2 mt-n3" color="teal" indeterminate></v-progress-linear>
        <v-card-actions></v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import eventBus from "@/utils/eventBus";
import { AuthService } from "@/modules/auth";
export default {
  name: "importSubs",
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("subscribers/getLists");
    });
  },
  data() {
    return {
      isValid: false,
      file: null,
      group: null,
      files: [],
      loaderState: false,
      rules: {
        required: [(value) => !!value || "Required."],
      },
    };
  },
  computed: {
    lists() {
      return this.$store.getters["subscribers/subscribersGetters"]("Lists");
    },
  },
  methods: {
    handleFileUpload(event) {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        const fileType = selectedFile.type;
        const validTypes = [
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "text/csv",
        ];

        if (
          validTypes.includes(fileType) ||
          selectedFile.name.endsWith(".csv") ||
          selectedFile.name.endsWith(".xlsx") ||
          selectedFile.name.endsWith(".txt")
        ) {
          this.file = selectedFile;
        } else {
          alert("Please select a valid Excel or CSV file.");
          event.target.value = null;
        }
      }
    },
    uploadFile() {
      if (this.files.length === 0 || this.group === null) {
        eventBus.emit("showToast", {
          type: "error",
          message: "All fields are required",
        });
      } else {
        this.loaderState = true;

        const formData = new FormData();
        formData.append("file", this.files[0]);
        formData.append("group_id", this.group);

        fetch("https://tmailapi.somocloud.org/api/subscribers/upload-csv/", {
          method: "POST",
          headers: {
            "Authorization": `Bearer ${AuthService.token}`
          },
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {
            eventBus.emit("showToast", {
              type: "success",
              message: "File uploaded successfully",
            });
            this.loaderState = false;
            this.$router.push({ name: "ListPage" });
            console.log("File uploaded successfully:", data);
          })
          .catch((error) => {
            this.loaderState = false;
            eventBus.emit("showToast", {
              type: "error",
              message: "Error uploading file",
            });
            console.error("Error uploading file:", error);
          });
      }
    },
  },
  mounted() {
    if (this.$route.params.id !== "") {
      this.group = parseInt(this.$route.params.id)
    } else {
      this.group = 0
    }
  }
};
</script>

<style scoped>
.file-upload {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid green;
  height: 200px;
}

.file-upload input {
  margin-bottom: 10px;
}

.file-upload button {
  margin-top: 10px;
}
</style>
