import dashboard from "../dashboard/views/dashboard";
import CampaignList from "../campaigns/components/allCampaigns";
import AddCampaign from "../campaigns/components/addCampaign";

const campaignRoutes = [
  {
    path: "/",
    component: dashboard,
    meta: { requiresAuth: true },
    children: [
      {
        path: "add-campaign/:id?",
        name: "AddCampaign",
        component: AddCampaign,
        meta: { requiresAuth: true },
      },
      {
        path: "all-campaigns",
        name: "CampaignList",
        component: CampaignList,
        meta: { requiresAuth: true },
      },
    ],
  },
];

export default campaignRoutes;
