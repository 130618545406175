import addTemplate from "../templates/components/addTemplate";
import templateList from "../templates/components/allTemplates";
import templateCards from "../templates/components/templateCardView";
import dashboard from "../dashboard/views/dashboard";

const subscribersRoutes = [
  {
    path: "/",
    component: dashboard,
    meta: { requiresAuth: true },
    children: [
      {
        path: "add-template/:id?",
        name: "addTemplate",
        component: addTemplate,
        meta: { requiresAuth: true },
      },
      {
        path: "temp-list",
        name: "templateList",
        component: templateList,
        meta: { requiresAuth: true },
      },
      {
        path: "temp-card",
        name: "templateCards",
        component: templateCards,
        meta: { requiresAuth: true },
      }
    ],
  },
];

export default subscribersRoutes;
