import call from "@/service/http";
import AuthConstants from "./authConstants";
import eventBus from "@/utils/eventBus";
import AuthService from "../auth/views/authService";

const authModule = {
  namespaced: true,
  state: {
   
  },
  mutations: {
  

    MUTATE: (state, payload) => {
      state[payload.state] = payload.value;
    },
  },
  getters: {
    // alert: (state) => state.alert,
    authGetters: (state) => (val) => state[val],
  },
  actions: {
    login: (_, data) => {
      call("post", AuthConstants.login, data)
        .then((res) => {
          if (res.data.status_code === 200) {
            AuthService.login(res.data.access);
            eventBus.emit("showToast", {
              type: "success",
              message: res.data.message,
            });
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: res.data.message,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    forgot: (_, data) => {
      call("post", AuthConstants.forgot, data)
        .then((res) => {
          console.log(res);
          eventBus.emit("showToast", {
            type: "success",
            message: "Check your Email",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    reset: ({ commit }, data) => {
      call("post", AuthConstants.reset, data)
        .then((res) => {
          console.log(res);
          commit("SET_ALERT", {
            status: "success",
            message: "success",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    acceptInvite: (_, data) => {
      call("post", AuthConstants.invite(data.token), data)
        .then((res) => {
          if (res.data.status_code === 200) {
            eventBus.emit("showToast", {
              type: "success",
              message: "wait as we redirect you to login",
            });
            eventBus.emit("acceptInvite");
          } else {
            eventBus.emit("showToast", {
              type: "error",
              message: res.data.message,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};

export default authModule;
