import call from "@/service/http";
import constants from "./emailConstants";
import eventBus from "@/utils/eventBus";


const emailModule = {
    namespaced: true,
    state: {
      loaderState: false,
      singleEmails:[],
      emails:[],
    },
    mutations: {
      MUTATE: (state, payload) => {
        state[payload.state] = payload.value;
      },
    },
    getters: {
      emailGetters: (state) => (val) => state[val],
    },
    actions: {
        getEmails: ({ commit }) => {
          commit("MUTATE", { state: "loaderState", value: true });
            call("get", constants.emails)
              .then((res) => {
                if (res.data.status_code === 200) {
                  commit("MUTATE", { state: "emails", value: res.data.data });
                } else {
                  eventBus.emit("showToast", {
                    type: "error",
                    message: "Error fetching Lists",
                  });
                }
                commit("MUTATE", { state: "loaderState", value: false });
              })
              .catch((err) => {
                console.log(err);
                commit("MUTATE", { state: "loaderState", value: false });
              });
          },
   
      getSingleEmails: ({ commit }, data) => {
        commit("MUTATE", { state: "loaderState", value: true });
        call("get", constants.singleList(data))
          .then((res) => {
           
            if (res.data.status_code === 200) {
              commit("MUTATE", { state: "singleEmails", value: res.data.data });
            } else {
              eventBus.emit("showToast", {
                type: "error",
                message: res.data.message,
              });
            }
            commit("MUTATE", { state: "loaderState", value: false });
          })
          .catch((err) => {
            console.log(err);
            commit("MUTATE", { state: "loaderState", value: false });
          });
      },
      getEmailsbyStatus: ({ commit }, data) => {
        commit("MUTATE", { state: "loaderState", value: true });
      
        const url = data.status === 'bounced' ? constants.status(data.id) : constants.deliveredStatus(data.id);
        if (url) {
          call("get", url)
            .then((res) => {
              if (res.data.status_code === 200) {
                commit("MUTATE", { state: "singleEmails", value: res.data.data });
              } else {
                eventBus.emit("showToast", {
                  type: "error",
                  message: res.data.message,
                });
              }
              commit("MUTATE", { state: "loaderState", value: false });
            })
            .catch((err) => {
              console.log(err);
              commit("MUTATE", { state: "loaderState", value: false });
            });
        } else {
          commit("MUTATE", { state: "loaderState", value: false });
        }
      },
      
    },
  };
  
  export default emailModule;
  