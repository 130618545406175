// store.js (main store)
import { createStore } from "vuex";
import authModule from "./modules/auth/authStore";
import dashboardModule from "./modules/dashboard/dashboardStore";
import subscribersModule from "./modules/subscribers/subscribersStore";
import listModule from "./modules/lists/listStore";
import templatesModule from "./modules/templates/templatesStore";
import campaignModule from "./modules/campaigns/campaignStore";
import emailModule from "./modules/emails/emailStore";
import rolesModule from "./modules/roles/rolesStore";
import eventBus from "./utils/eventBus";

const store = createStore({
  state: {
    loading: false,
  },
  mutations: {
    SET_LOADING: (state, payload) => {
      state.loading = payload;
    },
  },

  getters: {
    loading: (state) => state.loading,
  },

  actions: {
    showToast(_, { type, message }) {
      // Removed unused 'commit'
      eventBus.emit("showToast", { type, message });
    },
  },
  modules: {
    auth: authModule,
    dashboard: dashboardModule,
    subscribers: subscribersModule,
    list: listModule,
    templates: templatesModule,
    campaign: campaignModule,
    email:emailModule,
    roles: rolesModule,

    // Other modules can be added here
  },
});

export default store;
